import {useEffect, useState} from 'react';

/**
 * Observes elements and fires the callback when the first visible element changes.
 */
export const useFirstVisibleElement = <T extends HTMLElement>(
    observedElementsProvider: () => T[],
    visibleElementCallback: (firstVisibleElement: T | null) => void,
    headerHeight: number,
) => {
    const [firstVisibleElement, setFirstVisibleElement] = useState<T | null>(null);

    useEffect(() => {
        visibleElementCallback(firstVisibleElement);
    }, [visibleElementCallback, firstVisibleElement]);

    const [rootMargin, setRootMargin] = useState<string>('0px');

    useEffect(() => {
        setRootMargin(`-${headerHeight}px 0px 0px 0px`);
    }, [headerHeight]);

    useEffect(() => {
        if (typeof IntersectionObserver === 'undefined') {
            // SSR or old browser.
            return;
        }

        const observedElements = observedElementsProvider();
        const visibilityByElement = new Map<T, boolean>();

        const manageVisibility = (entries: IntersectionObserverEntry[]) => {
            for (const entry of entries) {
                visibilityByElement.set(entry.target as T, entry.isIntersecting);
            }
        };

        const manageFirstVisibleElement = () => {
            const visibleElements = Array.from(visibilityByElement.entries())
                .filter(([, value]) => value)
                .map(([key]) => key);

            setFirstVisibleElement(visibleElements[0] ?? null);
        };

        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                manageVisibility(entries);
                manageFirstVisibleElement();
            },
            {
                rootMargin,
                threshold: [0.0, 1.0],
            },
        );

        observedElements.forEach((element) => {
            visibilityByElement.set(element, false);
            observer.observe(element);
        });

        return () => observer.disconnect();
    }, [rootMargin, observedElementsProvider, visibleElementCallback]);
};
