import {AppShell, Burger, Group, Loader, useDisclosure} from '@components/mantine';
import {createFileRoute, Outlet, ScrollRestoration, useRouterState} from '@tanstack/react-router';
import {useEffect} from 'react';
import {useAuthentication} from '../auth';
import {TopBar} from '../components/layout';
import {SideNavigation} from '../components/navigation';
import {TableOfContents} from '../components/table-of-content/TableOfContent';
import {Unauthorized} from './unauthorized';

const AuthLayout = () => {
    const {canAccess} = useAuthentication();
    const [opened, {toggle, close}] = useDisclosure();
    const selected = useRouterState({
        select: (state) => state.location.pathname,
    });

    // close sidebar on mobile when route changes
    useEffect(() => {
        close();
    }, [selected]);

    if (canAccess === false) {
        return <Unauthorized />;
    } else if (canAccess === undefined) {
        return <Loader />;
    }

    return (
        <AppShell
            header={{height: 60}}
            navbar={{
                width: 298,
                breakpoint: 'sm',
                collapsed: {mobile: !opened},
            }}
            aside={{
                width: 298,
                breakpoint: 'lg',
                collapsed: {desktop: false, mobile: true},
            }}
            data-testid="application-container"
        >
            <AppShell.Header>
                <Group align="flex-start" h="100%">
                    <TopBar>
                        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                    </TopBar>
                </Group>
            </AppShell.Header>
            <SideNavigation />
            <AppShell.Main>
                <Outlet />
                <ScrollRestoration />
            </AppShell.Main>
            <AppShell.Aside withBorder={false}>
                <TableOfContents />
            </AppShell.Aside>
        </AppShell>
    );
};

export const Route = createFileRoute('/_auth')({
    component: AuthLayout,
});
