---
title: '@core/store'
metaTitle: 'Admin UI | Core - Store package'
metaDescription: 'How to add react-redux store in your package'
---

This package contains our various tools for storing application state.

-   The [Redux](https://redux.js.org/) store that should be used by all the packages that use Redux.
    -   It offers methods to get and modify the store, and expose a method to generate a new store (useful for Unit Tests).
    -   The unified store allow all packages to use any reducer and get any part of the unified state.
-   The `createFastContext` method, which creates a context that can handle very fast state changes with minimal rerendering.
    -   [The idea came from developer Jack Herrington](https://www.youtube.com/watch?v=ZKlXqrcBx88)

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/store@*" react-redux redux redux-thunk

or

pnpm add "@core/store@*" react-redux redux redux-thunk --recursive --filter <your-package>
```

## User Guide

### Redux - getStore

Returns the Redux store of the application.

```typescript
# ./store/UserStore.ts
import {getStore} from '@core/store';

interface UserState = {
    myProperty: number;
};

export const UserStore = getStore<UserState>(); // store.getState().myProperty will exist
```

### Redux - addReducer

Add one or multiple reducers to the store

_This function should only be called in a `SetupPackage.ts` file which should be marked as a file with side effect in the package.json file._

Add a single reducer

```typescript
import {CounterReducer} from './reducers/CounterReducer';

addReducer('counter', CounterReducer);
```

Add multiple reducers

```typescript
# ./reducers/RootReducer.ts
import {ReducersMapObject} from 'redux';

import {CounterReducer} from './reducers/CounterReducer';
import {PagerReducer} from './reducers/PagerReducer';

interfae MyPackageState {
    counter: number;
    pager: number;
};

const MyPackageReducers: ReducersMapObject<MyPackageState> = {
    counter: CounterReducer,
    pager: PagerReducer,
};

addReducer(MyPackageReducers);
```

### Redux - removeReducer

Remove one or multiple reducers from the store

**Remove a single reducer**

```typescript
removeReducer('counter');
```

**Remove multiple reducers**

```typescript
removeReducer(['counter', 'potatoes']);
```

### Redux - generateStore

Generates a brand new store containing only the Plasma reducers by default. You can initialize the store with new reducers and an initial state. This method should only be used in unit tests.

```typescript
import {commonReducers} from '@coveord/jsadmin-common';

import {reducers} from './reducers';

const {store, manager} = generateStore({...commonReducers, ...reducers}, initialState);

// You can still dynamically add reducers
manager.addReducer('key', someReducer);

// or remove reducers
manager.removeReducer('key');
```

### Redux - Reducer generators

Reducer generators are functions that return a reducer for a particular type of value. We realized that we were always writing pretty much the same reducer over and over, so we figured we could generalize it into functions. One function for each value type we want to manage with the reducer.

#### KeyValueReducerGenerator

Generates the most simple type of reducer: a key in the state that points to a single value.

Typically used to store a single `boolean`, `string`, `number`, but can also store arrays and objects that you want to overwrite completely every time.

```ts
// declaring reducer
import {KeyValueReducerGenerator} from '@core/store';

export const reducers: ReducersMapObject = {
    license: KeyValueReducerGenerator<LicenseModel>('license'),
};

const store = generateStore(reducers);

// updating value
import {setKeyValue} from '@core/store';
import {useDispatch} from 'react-redux';

const dispatch = useDispatch();
dispatch(setKeyValue('license', licenseModel));
```

#### ObjectReducerGenerator

Generates a reducer capable of managing an object. It comes with actions that cover most operations on objects like merging existing properties with new ones or update a single property within the object without impacting the others.

```ts
// declaring reducer
import {ObjectReducerGenerator} from '@core/store';

export const reducers: ReducersMapObject = {
    license: ObjectReducerGenerator<LicenseModel>('license'),
};

const store = generateStore(reducers);

// updating value
import {ObjectKeyValueActions} from '@core/store';
import {useDispatch} from 'react-redux';

const dispatch = useDispatch();
dispatch(ObjectKeyValueActions.update('license', licenseModel));
```

### createFastContext

`createFastContext` is used in much the same way as [React's context](https://react.dev/reference/react/createContext), and adds a number of additional tools to help you interact with it.

This context is perfect when you're looking for performance for your functionality and multiple data sharing. It creates a context capable of handling very rapid state changes with a minimum of rendering.

If you'd like to learn more, [take a look at the video of designer Jack Herrington](https://www.youtube.com/watch?v=ZKlXqrcBx88).

#### Provider

The `Provider` component is placed around your functionality so that each of your components can be a child of the Provider component.
Each of your child components will be able to interact with the `createFastContext`.

Here's an example of how to use the Provider and initialize it

```tsx
# ./src/context/MyFeatureContext.tsx
import {createFastContext} from '@core/store';

type MyStateType = {
    myProperty: number;
};

const {Provider} = createFastContext<MyStateType>();

export const MyFeatureContext = {
    Provider,
};

# ./src/pages/MyFeaturePages.tsx
import {Page} from '@components/page';

import {MyFeatureContext} from '../context';
import {Locales} from '../strings';

export const MyFeaturePage: FunctionComponent = () => (
    <Page
        title={Locales.format('MyFeature.metaTitle')}
        canRender
    >
        <MyFeatureContext.Provider value={{myProperty: 42}}>
            ...
        </MyFeatureContext.Provider>
    </Page>
);
```

#### useSelector

`useSelector` is a hook that allows you to select a part of the state from store of context.

```tsx
# ./src/context/MyFeatureContext.tsx
import {createFastContext} from '@core/store';

type MyStateType = {
    myProperty: number;
};

const {useSelector} = createFastContext<MyStateType>();

const useState = <P extends keyof MyStateType>(): MyStateType[P] => useSelector((store) => store[property]);

export const MyFeatureContext = {
    useState,
};

# ./src/components/MyComponent.tsx
import {MyFeatureContext} from '../context';
import {Locales} from '../strings';

export const MyComponent: FunctionComponent = () => {
    const myProperty = MyFeatureContext.useState('myProperty');

    return <span>{Locales.format('MyFeature.myProperty', {myProperty})}</span>;
};
```

#### useStore

`useStore` is a hook that allows you to modify the entire store context.

```tsx
# ./src/context/MyFeatureContext.tsx
import {createFastContext} from '@core/store';

type MyStateType = {
    myProperty: number;
};

const {useStore} = createFastContext<MyStateType>();

const useMyFeature = () => {
    const setState = useStore();

    return {
        setState,
    };
};

export const MyFeatureContext = {
    useMyFeature,
};

# ./src/components/MyComponent.tsx
import {MyFeatureContext} from '../context';
import {Locales} from '../strings';

export const MyUpdateComponent: FunctionComponent = () => {
    const {setState} = MyFeatureContext.useMyFeature();

    return (
        <button onClick={() => setState({myProperty: 24})}>
            {Locales.format('MyFeature.updateMyProperty')}
        </button>
    );
};
```

#### useHandler

`useHandler` is a hook that allows you to create a function that will modify the store context.

```tsx
# ./src/context/MyFeatureContext.tsx
import {createFastContext} from '@core/store';

type MyStateType = {
    myProperty: number;
    displayProperty: boolean;
};

const {useHandler} = createFastContext<MyStateType>();

const useMyFeature = () => {
    const handlePreview = useHandler((state: MyStateType, setState: (value: Partial<MyStateType>, isDisplayed: boolean) => void) => {
        setState({...state, displayProperty: isDisplayed});
    });

    return {
        handlePreview,
    };
};

export const MyFeatureContext = {
    useMyFeature,
};

# ./src/components/MyComponent.tsx
import {MyFeatureContext} from '../context';
import {Locales} from '../strings';

export const MyUpdateComponent: FunctionComponent = () => {
    const {handlePreview} = MyFeatureContext.useMyFeature();

    return (
        <>
            <button onClick={() => handlePreview(false)}>
                {Locales.format('MyFeature.cancelPreview')}
            </button>
            <button onClick={() => handlePreview(true)}>
                {Locales.format('MyFeature.displayPreview')}
            </button>
        </>
    );
};
```
