---
title: 'Feature Preview'
metaTitle: 'Admin-UI Docs | Announcements > Feature Preview'
metaDescription: ''
---

With the goal of fostering better collaboration among teams and putting more visibility on what awesome project every team is developing, we are starting a new process : the _Feature Preview_

The goal : presenting the project and create a discussion on the _execution_ of the project. The goal is not to discuss the _what_, but the _how_.

What it’s _not_ :

-   A UX discussion
-   A feature & usability discussion
-   A place to discuss what feature should be implemented or comment on a feature

What it is :

-   A developer discussion on how to build the feature
-   An opporunity to gather good advice
-   A place to make sure you don't reinvent the wheel

This Feature preview has 2 steps :

-   Fill a Feature Preview document. We have a [template here](https://coveord.atlassian.net/wiki/spaces/RD/pages/3696951424/Feature+Preview+Template) , but feel free to adjust it to your reality
-   Organize a meeting, including DEVELOPERS (and only developers) from all teams discussing about the _implementation_ of the project. Please post your invite on the [admin-ui-guild](https://coveo.slack.com/archives/C01M10DME91) so all interested developers can join.

We strongly encourage you to start this process in your teams, as we are all eager to learn about the project you will be working on and see how you plan to implement it !

You can find some examples of past Feature Preview in our [Confluence page](https://coveord.atlassian.net/wiki/spaces/RD/pages/3702227015/Feature+Preview)
