---
title: 'Learn the basics'
metaTitle: 'Admin-UI Docs | Learn the basics'
metaDescription: 'A summary page of learning the basics and getting help you need.'
---

Hello welcome to the Admin-UI official handbook where we will show you the guides on how to develop, create and make new features in our current admin-ui repo

## Getting help

Admin-UI project has a very friendly community, we are always happy to help you get started:

-   🐒 [Join the Guild channel on slack](https://coveo.slack.com/archives/C01M10DME91) - ask anything about general development issues.
-   ❓ [Join the Support Admin-UI channel on slack](https://coveo.slack.com/archives/CTDL3QQ9W) - ask anything about how the project works or talk about a potential bug.
-   🎨 [Join the Support design channel on slack](https://coveo.slack.com/archives/C06KN1B85C1) - ask for help on functional or style design.
-   ️📝 [Join the "Documentation Team / Admin-UI" channel on slack](https://coveo.slack.com/archives/C03SR3XJJSH) - ask for help on copy.
-   🔧 [Join the Builds channel on slack](https://coveo.slack.com/archives/CT3DGPG8H) - see the alerts of the different builds of the project.
-   👀 [Join the Sentry Dev channel on slack](https://coveo.slack.com/archives/C01AHG4C8EN) - see the errors reported by sentry on the development platform.

If you want to know more about what is being planned, you can also follow the reports from [UI Guild meetings](https://coveord.atlassian.net/wiki/spaces/RD/pages/2875785299/UI+Guild+meetings) & [Features team meetings](https://coveord.atlassian.net/wiki/spaces/RD/pages/2874835378/Features+team+meetings) on Confluence.

You can also [ask to join](mailto:design@coveo.com) the "🎬 UX, Tooling & PM Demos" presentations to know the latest trends :)

## Nobody's perfect

If you find a bug or have improvements you would like to suggest, please submit a ticket in [the PM Request Jira project](https://coveord.atlassian.net/browse/PMR).

To help you write a good Jira issue, we advise you to follow "[Guidelines for better Jira bug description](https://coveord.atlassian.net/wiki/spaces/RD/pages/2225439484/Jira+bug+description+guideline)".

To write the ticket and [choose the right unit](https://coveord.atlassian.net/wiki/spaces/RD/pages/2895020174/Who+Owns+What+in+R+D), we develop a tool in Admin-UI project.

![](./images/learn-the-basics-admin-ui-header.png)

In the header, the last button on the right (**"Report a problem with the current panel""**), will redirect you to the creation of a preconfigured ticket in JIRA.

![](./images/learn-the-basics-jira-create-ticket.png)

All that remains is to complete this ticket by following [our documentation](https://coveord.atlassian.net/wiki/spaces/RD/pages/2225439484/Jira+bug+description+guideline).
