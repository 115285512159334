---
title: 'IDE software'
metaTitle: 'Admin-UI Docs | Getting started > Integrated Development Environment software'
metaDescription: 'Configure your preferred IDE to help you become more productive!'
---

You can use the IDE you prefer to work in the admin-ui, but we recommend using either [Visual Studio Code](https://code.visualstudio.com) (free) or [WebStorm](https://www.jetbrains.com/webstorm) (paid).

## Visual Studio Code

[Visual Studio Code](https://code.visualstudio.com) is a lightweight but powerful source code editor which runs on your desktop and is available for Windows, macOS and Linux.
It comes with built-in support for JavaScript, TypeScript and Node.js.

### Configuring your extensions

Your local VSCode settings live in the `./.vscode/settings.json` file.
This document should not be committed, being tailored for your local environment.

[Learn more about User and Workspace Settings](https://code.visualstudio.com/docs/getstarted/settings)

#### ESLint extension settings

1. Install the [`ESLint` extension](https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint) maintained by Microsoft.
2. In `./.vscode/settings.json`, add some eslint configs.
    ```json
    {
        [...],
        "editor.codeActionsOnSave": [
            "source.organizeImports",
            "source.fixAll.eslint"
        ],
        "eslint.enable": true,
        "eslint.useFlatConfig": true,
        "eslint.validate": ["javascript", "javascriptreact", "typescript", "typescriptreact"],
    }
    ```

#### Prettier extension settings

1. Install the [`Prettier` extension](https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode) maintained by Prettier.
2. In `./.vscode/settings.json`, add:
    ```json
    {
        [...],
        "editor.defaultFormatter": "esbenp.prettier-vscode",
    }
    ```

#### Auto-format settings

In `./.vscode/settings.json`, add:
    ```json
    {
        [...],
        "editor.formatOnSave": true,
        "editor.formatOnPaste": true,
    }
    ```

### List of suggested extensions

Once you are done installing the mandatory extensions (ESLint and Prettier), you can start looking into other ones to make your life easier or just make your VSCode nice to look at.

Here's a non-exhaustive list of our favorite extensions:

#### Must have / Highly recommended

- [IntelliCode](https://marketplace.visualstudio.com/items?itemName=VisualStudioExptTeam.vscodeintellicode)
- [GitLens — Git supercharged](https://marketplace.visualstudio.com/items?itemName=eamodio.gitlens)
- [Path Intellisense](https://marketplace.visualstudio.com/items?itemName=christian-kohler.path-intellisense)
- [Git History](https://marketplace.visualstudio.com/items?itemName=donjayamanne.githistory)

#### Quality of life improvements

Bracket matching is now built-in, you can [turn in on in the settings directly](https://code.visualstudio.com/docs/editor/editingevolved#_bracket-pair-colorization)

- [Auto Close Tag](https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-close-tag)
- [Color Highlight](https://marketplace.visualstudio.com/items?itemName=naumovs.color-highlight)
- [npm Intellisense](https://marketplace.visualstudio.com/items?itemName=christian-kohler.npm-intellisense)
- [Auto Rename Tag](https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-rename-tag)
- [indent-rainbow](https://marketplace.visualstudio.com/items?itemName=oderwat.indent-rainbow)
- [Trailing Spaces](https://marketplace.visualstudio.com/items?itemName=shardulm94.trailing-spaces)
- [Better Comments](https://marketplace.visualstudio.com/items?itemName=aaron-bond.better-comments)
- [Turbo Console Log](https://marketplace.visualstudio.com/items?itemName=ChakrounAnas.turbo-console-log)
- [Relative Path](https://marketplace.visualstudio.com/items?itemName=jakob101.RelativePath)
- [Auto Import](https://marketplace.visualstudio.com/items?itemName=steoates.autoimport)
- [Path Autocomplete](https://marketplace.visualstudio.com/items?itemName=ionutvmi.path-autocomplete)

#### Languages related

- [Sass](https://marketplace.visualstudio.com/items?itemName=Syler.sass-indented)
- [YAML](https://marketplace.visualstudio.com/items?itemName=redhat.vscode-yaml)
- [Markdown All-In-One](https://marketplace.visualstudio.com/items?itemName=yzhang.markdown-all-in-one)
- [DotENV](https://marketplace.visualstudio.com/items?itemName=mikestead.dotenv)
- [TypeScript Hero](https://marketplace.visualstudio.com/items?itemName=rbbit.typescript-hero)

#### Some VSCode life hacks

```json
{
    "telemetry.telemetryLevel": "off", // disables telemetry
    "breadcrumbs.enabled": false, // removes breadcrumbs under file
    "editor.minimap.enabled": false // removes the minimap (hardly used and not so useful)
    "workbench.startupEditor": false, // removes the early startup editor of VScode, annoying popups sometimes
    "editor.renderIndentGuides": false, // removes the indentGuide, makes vscode slightly cleaner
    "workbench.editor.showTabs": false // removes open tabs visualization
}
```

## WebStorm

[WebStorm](https://www.jetbrains.com/webstorm) is a more complete IDE, you won't need to install any plugin to get a good developer experience.
There's a free trial if you want to try it, but if you choose to use it you'll need to [request a license](https://www.coveogo.com/sp?id=sc_cat_item&sys_id=a7cb7b931b713410dc685468624bcb39&referrer=popular_items).

Here are some documentation links to help you configure WebStorm correctly:

- [How to move from VS Code to WebStorm](https://www.jetbrains.com/help/webstorm/how-to-migrate-from-vs-code-to-webstorm.html)
- [Configure ESLint automatically](https://www.jetbrains.com/help/webstorm/eslint.html#ws_js_eslint_automatic_configuration)
- [Prettier](https://www.jetbrains.com/help/webstorm/prettier.html)
- [Activate and configure Stylelint](https://www.jetbrains.com/help/webstorm/using-stylelint-code-quality-tool.html#ws_stylelint_configure)
- [Trigger actions when saving changes](https://www.jetbrains.com/help/webstorm/saving-and-reverting-changes.html#actions-on-save)

## GitHub Copilot & your IDE

First of all, the Admin-UI project is eligible for use with [GitHub Copilot](https://github.com/features/copilot), which is not the case for all Coveo projects.

[Learn more about GitHub Copilot for Business at Coveo](https://coveord.atlassian.net/wiki/spaces/CGTCR/pages/3531636895/GitHub+Copilot+for+Business+specific+guidelines)

[Learn more about Using GitHub Copilot at Coveo](https://coveord.atlassian.net/wiki/spaces/DA/pages/4136239105/2024-05-31+Using+Copilot)

### Request a GitHub Copilot license

[A “Request / Modify Access” request must be made on our CoveoGo portal](https://www.coveoat.coveo.com/coveogo-forms) to gain access to GitHub Copilot.

### Configure VSCode

VSCode's documentation is pretty well done, and [a nice section on GitHub Copilot](https://code.visualstudio.com/docs/copilot/overview) is already present.
The [GitHub Copilot extension](https://marketplace.visualstudio.com/items?itemName=GitHub.copilot) must be installed in your VSCode.

### Configure WebStorm

The [GitHub Copilot](https://plugins.jetbrains.com/plugin/17718-github-copilot) must be installed in your WebStorm.
