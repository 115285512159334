---
title: 'Playwright Guidelines'
metaTitle: 'Admin UI Docs | Tests > Playwright Guidelines'
metaDescription: 'Guidelines for Playwright End-to-End tests'
---

## Getting started

The Playwright framework is mainly used to create End-To-End tests covering the Admin UI code. Please refer to the [README](https://github.com/coveo/admin-ui/blob/master/e2e/README.md) file in the Admin UI repository for local setup, authentication, custom organization and platform client usage.

## Best practices

These should help make sure everyone follows the same path when adding new tests.

### e2e folder architecture

According to the [ADR 003 - Standardization of tests](https://github.com/coveo-platform/admin-ui/blob/master/docs/adr/003-standardization-of-tests.md#end-to-end-test), the **test folder** structure should correspond to the menu of the Coveo Administration Console. If needed, add a sub-folder to group tests by feature.

The **api folder** architecture should follow the structure of the Coveo Platform API as seen on [Swagger UI](https://platformdev.cloud.coveo.com/docs?).

The **data folder** should be based on the test folder architecture.

### Naming convention

The **spec file** should be named after the feature or the UI element or the behavior being tested.

According to the [ADR 003 - Standardization of tests](https://github.com/coveo-platform/admin-ui/blob/master/docs/adr/003-standardization-of-tests.md#end-to-end-test), each test spec file should have a **describe** with that file name as a title. We also recommend to add [Tags](#tags) here.

The tests can be grouped further by nested describes if needed. The **nested describe title** and the **test title** should describe the tested requirement, expected input or state, and expected behavior. 

For example:

```
test.describe(`crawlingModuleSourceDependsOnUserPrivilege ${tags.sections.content} ${tags.teams.connectivityCore}`, () => {
    test.describe('given Crawling Module View privilege', () => {
        test('can create a Crawling Module category source', async ({page}) => {
```

The **resources** created in the test should have such a name that would be unique and would help to identify what test created it:

- it should be prefixed with the spec file name
- another keyword could be added to point to the test in the file that created it
- to make the name unique, you can suffix a random id to it
- finally, it’s good to suffix the datetime as well (it will help to see easily in the UI when the resource was created)

Suggested format: `{specFileName}_[{keyword}]_{randomId}_{dateTime}`

### Test setup

You can use the permanent test environment setup (organization and resources) for the tests that need the organization provisioning, e.g. for the test that rebuilds a source. See the details on the existing permanent test environments [here](https://github.com/coveo-platform/admin-ui/blob/master/e2e/README.md#testing-a-specific-coveo-organization).

When using the permanent environment setup, 
- Check in the before hook if the required setup is in place (e.g. a custom group). If it's not, recreate it and/or fail the test. Have the required setup described in the test.
- Have a dedicated user for each permission setup.

For other tests, create a temporary environment setup before the test starts (create a new organization and/or required resources via API, invite the test user to a group, etc.) and delete it after. Make sure the temporary environment is deleted even if the test fails. The setup is not part of the test, do the test-specific setup in the before hook and clear it in the after hook.

Example:

```
import {createOrganization, deleteOrganization} from 'api';
import {testUtilities} from 'helpers';

let trialOrgId;

test.describe(`organizationTab ${tags.sections.organization} ${tags.teams.organization}`, () => {
    test.beforeAll(async ({platformClient}) => {
        trialOrgName = `organizationTab_${testUtilities.generateRandomID()}_${testUtilities.getDateString()}`;
        trialOrgId = await createOrganization(platformClient, {
            name: trialOrgName,
            organizationTemplate: 'CommerceEnterpriseTrial',
        }).id;
    });

    test.afterAll(async ({platformClient}) => {
        await deleteOrganization(platformClient, trialOrgId);
    });

    test(`Details panel has expected information`, async ({page}) => {
        ...
```

Remember, the tests should be as much independent as possible.

### Parallel testing

The entire project is configured to run in parallel using `testConfig.fullyParallel`. By default, all tests will run in parallel on 3 browsers: each one is linked to a different organization which are defined in [setupOrganization.ts](https://github.com/coveo/admin-ui/blob/master/e2e/helpers/organization/setupOrganization.ts). Always keep in mind each of your tests should be able to run multiple times at once.

You can instead use sequential testing for a single test file with `test.describe.configure({mode: 'serial'});`. This will make each test of the test file run one after the other.

### Skipping tests

Sometimes tests are expected to fail because of an ongoing issue. If said issue takes too long to get fixed, the test will end up failing repeatedly. In those cases, the failing step of the test could be skipped.

This can be done in two ways:

-   Skipping the whole test section: `test.skip('skipped test', async ({ page }) => {`
-   Skipping some steps with comments: `//SKIP await expect(page).toHaveTitle(/failure/);`

As a best practice, any skipped test must be linked to a JIRA task detailing the situation. This JIRA task must be visible in the code.

```
const config = await getSourceConfiguration(platformClient, sourceName);
/* SKIP: Temporarily removed this part of the test until it's fixed. See https://coveord.atlassian.net/browse/CTSVC-2679
expect(config.securityProviders['Email Security Provider'].typeName).toBe('Email'); */
const listObjects = retrieveOTGObject(config);
expect(listObjects.length).toEqual(2);
```

### Test Plans

Test plans are powerful tools that can be used to list all test coverage of a feature by test type : unit test, component test and End-To-End test. A good practice would be to add a TESTPLAN.md file at the root of the feature codebase, along with the README.md file.

Having a good Test Plan will allow the feature to have a well-defined test coverage and avoid test duplication. The Test Plan will also act as a single source of truth for all tests.

See the Activity Browser [TESTPLAN.md](https://github.com/coveo/admin-ui/blob/master/pages/activity-browser/TESTPLAN.md) file for example.

## Test @tags

Playwright allows using tags to only run targeted tests. See the [offical documentation](https://playwright.dev/docs/test-annotations#tag-tests).

### Listing tags

The best way to use tags is to make sure every team uses the same naming logic. To enable this, tags are listed in the `e2e/config/tags.ts` file. They can be grouped by sections, teams, etc.

### Using tags

In the code, tags should be added to the `describe` declaration of the test. The right tags should be picked from the `e2e/config/tags.ts` file, and new tags should first be added there.

To use the tags in order to run targeted test locally, you can use one of the following commands.

Base command:

```bash
pnpm test:e2e:tags "@tag-here"
```

To run multiple tags:

```bash
pnpm test:e2e:tags "@tag-one|@tag-two"
```

To skip tagged tests:

```bash
pnpm test:e2e:tags-invert "@skipped-tag-here"
```

You can also use tags when running the E2E workflow. See the documentation bellow at [End-to-End Tests workflow - Tags](#tags)

> Note: tags are simply text based. The best practice to use '@' to identify them, but you can technically look for any string. This means using `--grep "any text found in a describe declaration"` will work.

> Important: <br /> 
If your test uses a given source UI to test a feature other than the source UI itself, add the source tag on your test. This will be very useful when the UI of the source is modified. It will help to easily identify and run all the e2e tests that use that source UI. <br /> 
You can add the tag either to the `test.describe` declaration or to the `test` itself (if the source UI is only used in one of the tests there). If the source does not have an entry in the `e2e/config/tags.ts` file yet, add it into the `sources` constant there. 

## Using the @certifier tag

### Certifier definition

The `@certifier` tag is a special tag used for specific tests. The goal of the certifier is to guarantee no major issue is deployed to production through the continuous delivery process.

### Certifier requirements

In order to use the `@certifier` tag, a test must meet a number of requirements:

-   The test runtime must be **fast**: around 15 seconds to execute
-   The test must be **stable**: it should avoid flakiness (passing after a retry) or failures
-   The test must be **independent**: all required setup should be included within the test (counter-example: requires running in serial after another test to pass)
-   The test must cover **client-facing** features (example: a visible interface, counter-example: an API call)
-   The test must be **straightfoward**: it should avoid going through corner cases
-   The test must have clear **ownership**: it should include relevant tags to identify teams
-   The test must be actively **monitored**: any failure or flakiness should be quickly adressed (see [Slack notifications](https://github.com/coveo/admin-ui/tree/master/e2e#getting-slack-notifications-for-specific-tests))

The objective is to have strong, relevant tests which keep the Admin Console healthy while not slowing down deployments too much. To meet that objective, we must have quick, precise tests and good monitoring for a fast reaction time.

Those requirements should also serve as best practice for any E2E test, certifier or not. If every test strives to reach this level, the overall quality of our E2E tests and code will end up increasing.

> Note: as a best practice, BETA features should be added to the certifiers before releasing. This is a good way to make sure a new feature is stable and well covered before making it available to the public. Once released, the `@certifier` tag could be removed or kept depending if the feature still meet the requirements.

### Certifier process

During each new deployment, the pipeline will deploy to `dev` without running any certifier tests, allowing for rapid iterations. Once dev is deployed, it will run all `@certifier` tests for the app currently deploying against the `dev` environment. When a `@certifier` test fails, it should immediately be **prioritized**: a test failure will halt the deployment to `stg` environment and above (`prod`, `hipaa`) until it is no longer failing.

Please reach out in the [#admin-ui-playwright](https://coveo.slack.com/archives/C04R4UCUHG9) channel if you require assistance at any point.

#### 1) Investigate the issue

The first step is to investigate the failure at hand and determine what kind of error it is: a test failure or an actual bug.

A) A test failure

To see if a failure is caused by the test itself, you should try to reproduce the steps manually. If the covered feature works fine, the issue must be within the test and it is most likely caused by a code change.

A task should be created to fix the test, and the test should either be [skipped](#skipping-tests) or fixed without delay.

B) An actual bug

If you can reproduce the issue manually, the next step is to rejoice that the issue was not introduced in production 😌. A bug should be logged to fix the issue: once fixed, the failing test should be run in the pull request to make sure the issue is resolved.

#### 2) Communicate the issue

When blocking release deployments, please mention the situation in the [#admin-ui-builds](https://coveo.slack.com/archives/CT3DGPG8H) channel so your colleagues can be aware of it.

#### 3) Resume or block deployments

Depending on the impact of the issue, deployments could either be **resumed** or **blocked** until the fix is deployed. This decision should always be taken with relevant teams.

-   Keeping the deployments blocked until the issue or test is fixed is the safest way to proceed. It does not require any further input apart from deploying the fix itself.

-   Resuming a deployment through Spinnaker should be a last resort: fixing the issue at hand should be prioritized.

> Warning: always look at the **Full test report** before resuming a deployment. Your team channel will only include your own test notifications, but another team might have a different certifier failure during the same deployment. All failing `certifier` tests will also be logged into the [#admin-ui-builds](https://coveo.slack.com/archives/CT3DGPG8H) channel.

See [Dev Tooling documentation](https://coveord.atlassian.net/wiki/spaces/CM/pages/1807483134/Package+Approvals+Dashboard) on how to approve team-defined certifiers on Spinnaker.

### Adding a @certifier tag

Adding the `@certifier` tag to an E2E test is as simple as any other tag (see documentation [above](#using-tags)). Unlike other tags though, the certifier tag should be added to the relevant `test` instead of the `describe` declaration.

```
test.describe(`createEditWebSource ${tags.sections.content} ${tags.teams.websitesConnectivity} `, () => {
    test.describe('can use the Cloud Web source creation flow', () => {
        test(`can create a Cloud Web source ${tags.certifier}`, async (...) => {
            ...
```

> Note: When adding this tag, make sure your pull request mentions that you want your test to be part of the certifiers. You should also add relevant members from your team and from the ADUI core team.

## End-to-End Tests workflow

The End-to-End Tests workflow can be found on the Admin UI Github repository under the Actions tab [here](https://github.com/coveo/admin-ui/actions/workflows/e2e.yml).

It runs the whole Playwright test suite nightly, but can also be used to run tests manually with custom settings.

### Configuration

The e2e.yml configuration file can be found [here](https://github.com/coveo/admin-ui/blob/master/.github/workflows/e2e.yml) in the repository.

It's configured with a matrix to split the runs between the two main browsers: chromium and firefox.

Automated runs are scheduled with a cron job.

### Results

Open a workflow run to see a Summary of the 2 browsers results, the 2 execution Jobs and other details.

Results are reported in two ways : GitHub Annotations and HTML reports.

#### GitHub Annotations

The annotations can be found at the top of a workflow report. Each log can be expanded for more details, and a summary can be found at the end. <br/>
![details](./images/playwright-guidelines-annotation-details.png)
![summary](./images/playwright-guidelines-annotations-summary.png)

#### HTML reports

The HTML reports can be found at the very bottom on the workflow report, under Artifacts. Each browser will have it's own archive.

![artifacts](./images/playwright-guidelines-artifacts.png)

Opening the archive will show you detailed logs for each test, classed by status.

![html report](./images/playwright-guidelines-html-report.png)

#### Workflow logs

Clicking on one of the Jobs will let you see the execution logs. This is useful to debug the workflow itself.

![jobs](./images/playwright-guidelines-jobs.png)
![job logs](./images/playwright-guidelines-job-logs.png)

-   The `Run actions/checkout` step can show you which workflow branch was used for the run. Expand the `Checking out the ref` line near the end to see it.
-   The `Run Playwright tests` step starts with the full execution command, which indlues the tags used. It also shows all console.log from the tests.

### Demo branch

The workflow can be used to test code not yet deployed by using the live demo generated by a pull request. Once your PR is up and the live demo is available, you can copy your branch ID and add it to the optional _Demo Branch_ field when running the workflow. This will make the E2E tests run on the https://platformdev.cloud.coveo.com/admin/feature/your-branch-id-here/index.html?dev#/ URL.

![custom run](./images/playwright-guidelines-custom-run.png)

> Note: you will also need to change the code Branch from _master_ to your branch ID if your test file was modified.

### Tags

As mentioned [above](#using-tags), tags can be used to run or skip targeted tests. The workflow is configured to add the content of the Tags field after the `test` parameter and automatically adds the `--grep` parameter like so:<br/>
`pnpm test:e2e:tags "tags-field-content"`.

Here are a few examples on what can be used:

-   **a standard tag** (will run all tests with the website team tag): `"@WEB"`
-   **multiple tags** (will run all tests with the website or machine learning teams tag): `"@WEB|@MLX"`
-   **a filename found in the describe** (will run the createEditWebSource test file): `createEditWebSource`
-   **a test step describe** (will run a single test step from createEditWebSource): `can edit the Advanced settings block`

## Working with Launch Darkly flags

Many features are developped using [LaunchDarkly flags](/guides/launch-darkly-flag). This can lead to complex scenarios when you want your E2E tests to cover both the live feature and the ongoing changes hidden behind the flag.

There are multiple ways to handle this scenario. Here are two solutions we can suggest.

### Mocking Launch Darkly flags

This is the recommended solution.

Playwright proposes a [route](https://playwright.dev/docs/api/class-route) class to handle Network mocking. This can be used to mock a Laundh Darkly flag value for the current test without impacting any other test or modifying the flag value itself.

You only need to mock the route at the beginning of the test and change the flag value, and then navigate to the right panel as usual.

```
await page.route('https://app.launchdarkly.com/sdk/**', async (route) => {
        const response = await route.fetch();
        const json = await response.json();
        json['ld_flag_name_here'] = {...json['ld_flag_name_here'], value: true};
        await route.fulfill({response, json});
});
await page.goto(`#/${organizationId}/section/panel`);
```

The Playwright browser will act as if the flag is turned on for the remaining steps of the test.

Once the flag is enabled in all organizations, you can simply remove the route part and the test will already work. If you have a second test to cover the old beheviour, you can delete it at this point.

### Using a custom organization

If your Launch Darkly flag is configured to be active on a specific organization (not the default E2E orgs), you could simply target this custom organization in your test file.

The [customOrganizaionPool](https://github.com/coveo-platform/admin-ui/blob/master/e2e/helpers/organization/getCustomOrganization.ts) should be used for this.

```
test.beforeAll(async ({browser}) => {
        const context = await browser.newContext();
        organizationId = await getCustomOrganizationId('yourCustomOrganization');
        await setupOrganization(context, organizationId);
});
```

While simple, this solution multiplies the setup required for the test. Once the flag is enabled, you might end up having to modify the default organizations where the feature was never tested.

As a rule of thumb, the default E2E organizations should always be as close as possible to the Production environment. If a feature flag is not enabled in production, it should not be enabled in the default organizations either.

## Links 🔗

-   [Playwright - Best Practices](https://playwright.dev/docs/best-practices) : A good read when starting out with Playwright
-   [Playwright - VS Code extension](https://playwright.dev/docs/getting-started-vscode) : VS Code extension for Playwright
-   [Admin UI ADR](https://github.com/coveo/admin-ui/blob/master/docs/adr/003-standardization-of-tests.md) : Architectural Decision Records by the Admin UI team on Standardization of tests
-   [End-to-End Tests workflow](https://github.com/coveo/admin-ui/actions/workflows/e2e.yml) : GitHub Workflow running the E2E tests
-   [admin-ui/e2e](https://github.com/coveo/admin-ui/tree/master/e2e) : Admin UI end-to-end tests repository
