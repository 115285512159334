---
title: 'React 18 stability improvements'
metaTitle: 'Admin-UI Docs | Announcements > React 18 stability improvements'
metaDescription: ''
---

In the past few weeks, we've made significant updates to our admin-ui platform's app. Here's a recap of what what done.

# 1. React 18's concurrent renderer

One of the most notable changes is the adoption of React 18's new renderer. This update unlocks a host of powerful features for our application, including concurrent rendering, suspense, and automatic batching. These enhancements will improve the overall performance and prepare us for the next set of changes in future React updates.

[Learn more about React 18 and concurrent features here 📖](https://react.dev/blog/2021/12/17/react-conf-2021-recap#react-18-and-concurrent-features)

# 2. StrictMode

In addition to upgrading the renderer, we've also activated `<StrictMode>`. This is a helpful tool provided by React that aids us in catching common bugs in our components during the development phase. By enabling StrictMode, we're ensuring that our components are more reliable and less prone to unexpected behavior. Make sure you have a look at your browser's dev tools and consider fixing the warnings generated by your respective features.

[Learn more about StrictMode and its warnings here 📖](https://react.dev/reference/react/StrictMode)

# 3. New approach to declare routes

We've also made changes to the way we declare routes in our application. This new approach allows for easier lazy loading of pages, leading to better chunk optimizations. Not only does this improve the load times of our pages, but it also works better with Vite's Hot Module Replacement (HMR), making our development process smoother and more efficient.

Before, here's how you needed to declare a route

```tsx
// <package-name>/src/index.ts
import './routes/registerRoutes';

// <package-name>/src/routes/registerRoutes.tsx
import {Loadable} from '@coveord/jsadmin-common';

// The init-application event is triggered once, when the application is ready to render
document.addEventListener('init-application', () => {
    const MyPage = Loadable(() => import('./path/to/my/ComponentPage').then((module) => module.MyPage));

    registerRoute({
        path: `/:orgId/my/page`,
        component: <MyPage />,
    });
});
```

Now, here's how you declare a route

```ts
// <package-name>/src/index.ts
export {routes as packageNameRoutes} from './routes';

// <package-name>/src/routes/index.ts
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: '/:orgId/my/page',
        lazy: async () => {
            const {MyPage} = await import('../pages/MyPage');
            return {Component: MyPage};
        },
    },
];
```

As you can see from the snippets above, it relies less custom code and event based indirections. The code is more closely bound to `react-dom-router` so developers can simply follow [its documentation](https://reactrouter.com/en/main/route/route) and it will work.

# 4. Gulp removal

Lastly, we've removed [Gulp](https://gulpjs.com/) from our build process entirely. While Gulp has been a valuable tool in the past, we found that removing it from our build process has simplified things considerably. This change will make our build process faster and easier to maintain, allowing us to deliver updates more quickly.

# Closing remarks

These updates mark a significant step forward in the evolution of our admin-ui platform's app. We're excited about the improvements these changes unlock and look forward to continuing to enhance our application in the weeks and months to come.
