---
title: 'Node LTS version 20'
metaTitle: 'Admin-UI Docs | Announcements > Node LTS version 20'
metaDescription: ''
---

With all the packages, libraries and other tools deprecating older versions of Nodejs, we have decided to bump Nodejs to its latest LTS version, [_Node 20 LTS_](https://github.com/nodejs/node/blob/main/doc/changelogs/CHANGELOG_V20.md#20.10.0). This way we get the latest features and fixes of this essential environment.

For you to have a local setup equivalent to the one used for remote deployment and avoid having error during the CI of your pull requests, we **strongly** recommend you to update your nodejs version to the latest LTS.

First, find out which version you currently have on your machine using:

`node --version`

or

`node ls`

to display all of the versions you downloaded and which one you have

From there, you can download the latest LTS version of node and setting that version as your default node version using:

`nvm install --lts`

`nvm use --lts`

You could also set it as your default version using:

`nvm alias default 20.10.0` (at the time of this announcement)

We do not encourage you to set your default version to always get the latest `lts`, since you would locally get the next LTS major before we would have updated it in our deployment pipeline.

Once your version is updated and set on your machine, you can patiently wait for the next LTS!

Happy coding!
