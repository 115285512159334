---
title: 'FrontEnd Reporting and Dispatch issues'
metaTitle: 'Admin-UI Docs | Learn the basics > FERD issues'
metaDescription: 'FrontEnd Reporting and Dispatch (FERD) issues are JIRA tickets created by the Frontend Foundation team while inspecting the admin-ui'
---

# Overview

The [Frontend Foundation team](https://coveord.atlassian.net/wiki/x/NwA1_g) is spending time inspecting the admin-ui and identifying areas that need improvement.
Our mission is to standardize practices to accelerate R&D frontend development and ensure high-quality output, so we're creating JIRA tickets for these issues and categorizing them based on the type of problem.
This document will serve as a reference for all teams involved in managing and addressing these issues to better understand each issue type.

# Issue Types

The FrontEnd Reporting and Dispatch (FERD) process categorizes issues into the following types:

## mantine-conversion

During our investigation, we might stumble upon sections using deprecated technologies (Backbone, plasma-react, etc.).
In certain cases, those deprecated technologies will make the section harder to maintain and use.
If we judge that there are too many issues or that patching individual bugs would be inefficient, we might recommend converting the whole feature to Mantine.

## problematic-ux-flow

During our investigation, we might stumble upon sections where the user experience is bad, confusing, or different for no reason.
Poor UX flow can lead to user frustration, decreased productivity, and higher support costs.
In the same vein, a user flow that is different from other flows in the app while trying to achieve a similar goal can be confusing for users.

The JIRA ticket will explain why the UX flow is problematic and provide specific examples.

Most of these issues will be forwarded to the UX team for further investigation and recommendations.

## missing-test-coverage

Our investigation makes us look at the code. In some cases, we might notice that the feature lacks sufficient automated tests.
Without adequate test coverage, new issues can go unnoticed, and existing functionality can break without warning.

In the issues tagged missing-test-coverage, we will recommend adding tests to certain features or sections of the code.

## missing-ux-guidance

Sometimes we might stumble upon a pattern that is new or different from the rest of the app.
Inconsistent UX design can confuse users and diminish the overall quality of the application.
In such cases, we might recommend creating a UX guidance document to ensure consistency across the app.

The ticket should explain the case, its impact, and outline what the guidance should include for UX designers and QA testers.

Most of these issues will be forwarded to the UX team for them to make a decision.

## missing-foundation-guidance

Similarly to missing-ux-guidance, we might stumble upon code that is using a different pattern from what we're used to.
Missing written developer guidance can result in inconsistencies in the code and component behavior, making the code difficult to maintain, review, and extend.

The ticket should explain the missing guidance and its impact, and detail what the guidance should include for developers and code reviewers.

Most of these issues will be assigned to the Frontend Foundation team for them to make a decision and document it.

## problematic-implementation

Sometimes there are significant issues with the current implementation of a feature or component.
These issues can range from poor performance and bugs to maintainability challenges and non-compliance with project standards.

In such cases, we will provide detailed information on the problematic implementation, including specific examples and potential solutions.

# Deep Dive on the Process

## 1. Team Meetings

The Frontend Foundation regularly do meetings to identify issues within a specific section of the admin-ui project.
During these meetings, we discuss and document any problems or improvements needed.

## 2. Creating JIRA Tickets

As issues are identified during the investigations, we create JIRA tickets in the [FERD project](https://coveord.atlassian.net/jira/software/c/projects/FERD/boards/1173/backlog?atlOrigin=eyJpIjoiZDc0MmM0ZTk4MWQ2NDgyY2IxMDY3ZGNhMmYzMGEzOTIiLCJwIjoiaiJ9) to track and manage them.
Each ticket includes a detailed description of the issue, relevant screenshots or attachments, and any other pertinent information.

## 3. Categorizing Issues

Once tickets are created, we categorize them using the issue types which will be put as labels.
The labels will allow us to track the ticket more easily.

## 4. Dispatching Issues

After categorizing the issues, we dispatch (move) the tickets to the relevant JIRA projects.
Each team will be responsible for handling the issues that are dispatched to their project, but the Frontend Foundation team will remain available to answer questions.

This process will ensure that each issue is handled by the team best suited to address it.
