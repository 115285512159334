---
title: 'Mantine version 7'
metaTitle: 'Admin-UI Docs | Announcements > Mantine version 7'
metaDescription: ''
---

As you may already have noticed, we have finally succeeded to update the Mantine library to major version 7 in the following projects:

-   Admin Console
-   Merchandizing Hub
-   Login pages
-   Proxy error pages

Mantine v7 support lands in [@coveord/plasma-mantine version 53.0.0](https://github.com/coveo/plasma/releases/tag/v53.0.0). Be sure to consult the changelog to see what changed compared to the previous versions which only supported Mantine version 6.

As per usual, reach out to us in the #admin-ui-guild if you have any questions regarding this update or if you need assistance updating Mantine in your own project.
