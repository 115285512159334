---
title: 'Creating a page'
metaTitle: 'Admin-UI Docs | Best practices > Creating a page'
metaDescription: 'How to create a page in Admin-UI project'
---

The process of creating a page in the Admin-UI repository is quite simple. Here are the main steps:

## 1. Add the page route(s)

The page should export the routes array that will be added to the router. To do so, add the routes to the `/src/routes/index.ts` file of the page package you just created, modify the `/src/index.ts` to export the routes array properly and spread this array in the `applicationsRoute.ts` file after installing the new page in your application `/apps/platform` or `/apps/commerce-hub`.

```jsx
// in "/src/routes/index.ts
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: '_PATH_TO_PANEL_',
        lazy: async () => {
            const {TheComponent} = await import('../PATH_TO_COMPONENT_FILE');
            return {Component: TheComponent};
        },
    },
];

// in "/src/index.ts"
export {routes as thePageRoutes} from './routes';
```

## 2. Render a page

To make your work easier, we created [@components/page](https://github.com/coveo/admin-ui/blob/master/components/page/README.md).
The `canRender` and `canEdit` methods receive the `license` and `user` to validate privileges.

```tsx
import {Page} from '@components/page';
import {useGuard} from '@components/security';
import {Button} from '@coveord/plasma-react';
import {FunctionComponent} from 'react';

export const MyPage: FunctionComponent = () => (
    <Page
        // This will update the browser tab name
        title={Locales.format('myPageTitle')}
        // Whether the user can view the page or not
        canRender={({user, license}) => user.canViewX && license.properties.someProperty}
        // Whether the user can edit resources, this is optional
        canEdit={({user}) => user.canEditX}
    >
        <MyPageHeader />
        <MyPageContent />
    </Page>
);

const MyPageContent: FunctionComponent = () => {
    // canEdit will be true if user.canEditX is true
    const {canEdit} = useGuard();
    return (
        <div>
            <Button enabled={canEdit}>Click me</Button>
        </div>
    );
};
```

There you have it, the basis of a page.

## 3. Render modals

A common use case in the Admin-UI is to have a page and display some modals over it. Here's how we can modify the previous example to achieve that:

```tsx
import {Routes, Route, useNavigate, useParams, Navigate} from '@core/routes';
import {URIUtils} from '@coveord/jsadmin-common';
// ...

const MyPageContent: FunctionComponent = () => {
    const {canEdit} = useGuard();
    return (
        <>
            <div>
                <Button enabled={canEdit} link={URIUtils.linkToRoute('/my/page/create')}>
                    Create something
                </Button>
            </div>
            <Routes>
                <Route path="/create" element={<EditXModal />} />
                <Route path="/edit/:id" element={<EditXModal />} />
            </Routes>
        </>
    );
};

const EditXModal: FunctionComponent = () => {
    // id will be undefined when the route is `/create` and the path value on /edit/:id
    const {id} = useParams();
    const navigate = useNavigate();
    const {canEdit} = useGuard();
    return (
        // The guard prevents the display of the modal if the user doesn't have the required privileges. This can happen when reaching the URL via a link
        <Guard canRender={canEdit} fallback={<Navigate to="/my/page" />}>
            <ModalCompositeConnected
                // you don't have to add the org id when you navigate, it will be added automatically
                closeCallback={() => navigate('/my/page')}
                // We use openOnMount since the modal will only be rendered if the route match
                openOnMount
                // ...
            />
        </Guard>
    );
};
```

## 4. Testing a page

Using the code from the previous example, the test could look like this:

```tsx
import {MemoryRouter, Route, Routes} from '@core/routes';
import {LicenseSelectors, UserPrivilegesValidator} from '@coveord/jsadmin-common';
import {render, screen, userEvent, waitFor} from 'octopus';

import {MyPage} from './MyPage';

describe('<MyPage />', () => {
    it('hides the content when the license does not allow someProperty', () => {
        jest.spyOn(LicenseSelectors, 'getLicense').mockReturnValue({properties: {someProperty: false}});
        jest.spyOn(UserPrivilegesValidator.prototype, 'canViewX', 'get').mockReturnValue(true);

        // In our tests we need to use a router to make the hooks work. Memory router was made for this
        // Ref. https://reactrouter.com/docs/en/v6/routers/memory-router
        render(
            <MemoryRouter initialEntries={['/org-id/my/page']}>
                <Routes>
                    {/* Render the MyPage when the route match this path */}
                    <Route path="/:orgId/my/page/*" element={<MyPage />} />
                </Routes>
            </MemoryRouter>,
        );

        // If the license has a missing property, we expect some element to not be in the page
        expect(screen.queryByRole('button', {name: 'Create something'})).not.toBeInTheDocument();
    });

    it('hides the content when the user cannot view X', () => {
        jest.spyOn(LicenseSelectors, 'getLicense').mockReturnValue({properties: {someProperty: true}});
        jest.spyOn(UserPrivilegesValidator.prototype, 'canViewX', 'get').mockReturnValue(false);

        render(
            <MemoryRouter initialEntries={['/org-id/my/page']}>
                <Routes>
                    <Route path="/:orgId/my/page/*" element={<MyPage />} />
                </Routes>
            </MemoryRouter>,
        );

        // If the user does not have all the privileges, we expect some element to not be in the page
        expect(screen.queryByRole('button', {name: 'Create something'})).not.toBeInTheDocument();
    });

    it('displays the content when the user can view X and the license has someProperty', () => {
        jest.spyOn(LicenseSelectors, 'getLicense').mockReturnValue({properties: {someProperty: true}});
        jest.spyOn(UserPrivilegesValidator.prototype, 'canViewX', 'get').mockReturnValue(true);

        render(
            <MemoryRouter initialEntries={['/org-id/my/page']}>
                <Routes>
                    <Route path="/:orgId/my/page/*" element={<MyPage />} />
                </Routes>
            </MemoryRouter>,
        );

        // If the user has all the privileges, we expect some element to be in the page
        expect(screen.queryByRole('button', {name: 'Create something'})).toBeInTheDocument();
    });

    describe('creation modal', () => {
        beforeEach(() => {
            // Make sure all the requirements to display the modals are met for all the tests
            jest.spyOn(LicenseSelectors, 'getLicense').mockReturnValue({properties: {someProperty: true}});
            jest.spyOn(UserPrivilegesValidator.prototype, 'canViewX', 'get').mockReturnValue(true);
            jest.spyOn(UserPrivilegesValidator.prototype, 'canEditX', 'get').mockReturnValue(true);
        });

        it('renders the modal when the route match /my/page/create', async () => {
            // In this test the initial entry already points to the create modal but we could have clicked on the `Create something` button instead
            render(
                <MemoryRouter initialEntries={['/org-id/my/page/create']}>
                    <Routes>
                        <Route path="/:orgId/my/page/*" element={<MyPage />} />
                    </Routes>
                </MemoryRouter>,
            );

            await waitFor(() => expect(screen.getByRole('dialog')).toBeVisible());
        });
    });
});
```
