---
title: 'Admin-UI Project'
metaTitle: 'Admin-UI Docs'
metaDescription: 'The documentation homepage.'
---

import {Prerequisites} from '../components';

The Admin-UI project groups together several applications that make up the various Coveo administration consoles.

[Learn more about the vision of our Coveo product](https://coveord.atlassian.net/wiki/spaces/RD/pages/4192174138/Product+Vision)

**Prerequisites**

<Prerequisites />

## Platform application

This application, also known as Admin-UI, ADUI or JSAdmin, is the main administration interface for the Coveo product.

![Platform application](./images/admin-ui-project-platform-application.png)

Here are the URLs of the various instances of the **Platform** application:
- url of Production: [https://platform.cloud.coveo.com](https://platform.cloud.coveo.com)
    - Europe: [https://platform-eu.cloud.coveo.com](https://platform-eu.cloud.coveo.com)
    - Australia: [https://platform-au.cloud.coveo.com](https://platform-au.cloud.coveo.com)
    - Canada: [https://platform-ca.cloud.coveo.com](https://platform-ca.cloud.coveo.com)
- url of Production (hipaa): [https://platformhipaa.cloud.coveo.com](https://platformhipaa.cloud.coveo.com)
- url of Quality Assurance: [https://platformstg.cloud.coveo.com](https://platformstg.cloud.coveo.com)
- url of Development: [https://platformdev.cloud.coveo.com](https://platformdev.cloud.coveo.com)
    - Europe: [https://platformdev-eu.cloud.coveo.com](https://platformdev-eu.cloud.coveo.com)
- url of Local Machine: [http://localhost:9000/?dev](http://localhost:9000/?dev)
- url of Local Machine with SSL: [https://local.cloud.coveo.com:9000/?dev](https://local.cloud.coveo.com:9000/?dev)

## Commerce Hub application

This application, also known as Merchandizing Hub or Merch Hub, is a user interface built for merchandisers and category managers.

[Learn more about the vision of "Commerce Hub" application](https://coveord.atlassian.net/wiki/spaces/COM/pages/3328802972/The+Merchandising+Hub)

![Commerce Hub application](./images/admin-ui-project-commerce-hub-application.png)

Here are the URLs of the various instances of the **Commerce Hub** application:
- url of Production: [https://commerce.cloud.coveo.com](https://commerce.cloud.coveo.com)
    - Europe: [https://commerce-eu.cloud.coveo.com](https://commerce-eu.cloud.coveo.com)
    - Australia: [https://commerce-au.cloud.coveo.com](https://commerce-au.cloud.coveo.com)
    - Canada: [https://commerce-ca.cloud.coveo.com](https://commerce-ca.cloud.coveo.com)
- url of Production (hipaa): [https://commercehipaa.cloud.coveo.com](https://commercehipaa.cloud.coveo.com)
- url of Quality Assurance: [https://commercestg.cloud.coveo.com](https://commercestg.cloud.coveo.com)
- url of Development: [https://commercedev.cloud.coveo.com](https://commercedev.cloud.coveo.com)
    - Europe: [https://commercedev-eu.cloud.coveo.com](https://commercedev-eu.cloud.coveo.com)
- url of Local Machine: [http://localhost:9001/?dev](http://localhost:9001/?dev)
- url of Local Machine with SSL: [https://local.cloud.coveo.com:9001/?dev](https://local.cloud.coveo.com:9001/?dev)

## Knowledge Hub application

This application empowers **Knowledge Managers** to easily **manage**, **report**, **evaluate** and **troubleshoot** their knowledge and serve it through Out Of the Box Service Experiences (distribution channels) such as the Insight Panel, IPX, Case Assist and Search Pages.

[Learn more about the vision of "Knowledge Hub" application](https://coveord.atlassian.net/wiki/spaces/PRODUCT/pages/3822256148/Knowledge+Hub+Previously+Service+Hub)

![Knowledge Hub application](./images/admin-ui-project-knowledge-hub-application.png)

Here are the URLs of the various instances of the **Knowledge Hub** application:
- url of Production: [https://knowledge.cloud.coveo.com](https://knowledge.cloud.coveo.com)
    - Europe: [https://knowledge-eu.cloud.coveo.com](https://knowledge-eu.cloud.coveo.com)
    - Australia: [https://knowledge-au.cloud.coveo.com](https://knowledge-au.cloud.coveo.com)
    - Canada: [https://knowledge-ca.cloud.coveo.com](https://knowledge-ca.cloud.coveo.com)
- url of Production (hipaa): [https://knowledgehipaa.cloud.coveo.com](https://knowledgehipaa.cloud.coveo.com)
- url of Quality Assurance: [https://knowledgestg.cloud.coveo.com](https://knowledgestg.cloud.coveo.com)
- url of Development: [https://knowledgedev.cloud.coveo.com](https://knowledgedev.cloud.coveo.com)
    - Europe: [https://knowledgedev-eu.cloud.coveo.com](https://knowledgedev-eu.cloud.coveo.com)
- url of Local Machine: [http://localhost:9002/?dev](http://localhost:9002/?dev)
- url of Local Machine with SSL: [https://local.cloud.coveo.com:9002/?dev](https://local.cloud.coveo.com:9002/?dev)

## Docs application

You are currently on our documentation application only available to Coveo employees.

Here are the URLs of the various instances of the **Doc** application:
- url of Production: [https://docs.admin-ui.coveo.com](https://docs.admin-ui.coveo.com)
- url of Local Machine: [http://localhost:3000](http://localhost:3000)
