import {Anchor, Group, Space, Stack, Title} from '@components/mantine';
import {ListSize16Px} from '@coveord/plasma-react-icons';
import {Link, useNavigate, useRouterState} from '@tanstack/react-router';
import {FunctionComponent, MouseEvent, useCallback, useEffect, useState} from 'react';
import {useFirstVisibleElement} from '../../hooks/useFirstVisibleElement';
import classes from './TableOfContents.module.css';

const HEADER_HEIGHT = 60;

export const TableOfContents: FunctionComponent = () => {
    const [hasScrolledToElement, setHasScrolledToElement] = useState(false);
    const navigate = useNavigate();
    const state = useRouterState();
    const {id, params} = [...state.matches].reverse()?.[0];
    const getHeadings = useCallback(
        () => Array.from(document.querySelectorAll<HTMLHeadingElement>('h1, h2, h3, h4, h5')),
        [id],
    );
    const links = getHeadings();
    const [active, setActive] = useState<HTMLHeadingElement>(links?.[0]);

    useFirstVisibleElement(
        () => links,
        (heading) => {
            if (heading) {
                setActive(heading);
            }
        },
        HEADER_HEIGHT,
    );

    const getMaskParams = (hash: string) => ({
        to: '.',
        params: {
            ...params,
            id: 'id' in params ? params.id.replace(/\d+-/, '') : undefined,
        },
        hash,
    });

    const scrollToElement = (el: HTMLHeadingElement) => {
        // cannot use scrollIntoView because of the topBar
        const position = el.getBoundingClientRect();
        // scrolls to 110px above element
        window.scrollTo({
            left: position.left,
            top: position.top + window.scrollY - HEADER_HEIGHT,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (!hasScrolledToElement && state.location.hash) {
            const el = links.find((header) => header.id === state.location.hash.replace('#', ''));
            if (el) {
                scrollToElement(el);
                setHasScrolledToElement(true);
            }
        }
    }, [links]);

    const items = links.map((item) => (
        <Anchor
            component={Link}
            hash={item.getAttribute('id') ?? ''}
            to="."
            params={params as any}
            mask={getMaskParams(item.getAttribute('id') ?? '') as any}
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                scrollToElement(item);
                setTimeout(() => {
                    navigate?.({
                        hash: item.getAttribute('id') ?? '',
                        mask: getMaskParams(item.getAttribute('id') ?? '') as any,
                    });
                }, 300);
            }}
            key={item.innerText}
            data-active={active === item}
            className={classes.link}
            pl={`calc(${parseInt(item.getAttribute('data-order') ?? '', 10)} * var(--mantine-spacing-sm))`}
        >
            {item.innerText}
        </Anchor>
    ));

    return (
        <Stack gap={0} py="md" pr="xs">
            <Group gap="xs">
                <ListSize16Px height={16} />
                <Title component="span" order={4}>
                    Table of contents
                </Title>
            </Group>
            <Space h="xs" />
            {items}
        </Stack>
    );
};
