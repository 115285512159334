---
title: 'The tech stack basics'
metaTitle: 'Admin-UI Docs | Learn the basics > The tech stack basics'
metaDescription: 'Learn about the basics of the Admin-UI techstack.'
---

> Help, I don't know anything about TypeScript, React or Redux!

Well then, this place is for you!

While there is nothing better than trying out stuff by yourself, here are a couple of references to get you to know some of the basics.

> I know nothing about JavaScript either

Well, it is a language used to interact with elements on a HTML page. The best you can do is follow a bit of tutorials to understand the language's basics!

References:

-   https://www.codecademy.com/learn/build-interactive-websites

## TypeScript

TypeScript adds type annotations over JavaScript. Here is a quick overview:

```ts
let variable: string;
```

No big sorcery here, `: string` ensures that `variable` is a string!

You can also define interfaces:

```ts
interface Book {
    name: string;
    rating: number;
}
```

You can then define a variable with a specific interface:

```ts
let some: Book = {
    name: 'My First Interface',
    rating: 5 / 7,
};
```

This will do 2 important things compared to JavaScript:

-   The code will not compile if the assignment does not have all the `Book` attributes
-   The `Book` interface will completely disappear once compiled back to JavaScript

So, in essence, TypeScript is a _developer tool_ to prevent coding mistakes that can be done in JavaScript.

You can learn more here:

-   https://www.typescriptlang.org/docs/handbook/

### I see this weird JavaScript syntax with `...` and `{}`, what are those?

The following code is called "destructuring". It extracts values from a object. For instance:

```ts
const myObject = {a: '1', b: '2', c: '3'};
const {a, ...rest} = myObject;
```

This code "destructures" `myObject.a` into the `a` variable. So the variable `a` now holds `1`.

The spread operator `...` tells TypeScript to put the _rest_ of the key-values into `rest`, so the `rest` variable holds `{b: '2', c: '3'}`.

We use a couple of shortcuts like that because they are operations that we need to do quite often, and they get really easy to read once you get used to them.

Another example is the following:

```ts
const myObject = {a: '1'};
const myOtherObject = {b: '2'};
const merged = {
    ...myObject,
    ...myOtherObject,
    c: '3',
};
```

Here, the spread operator `...` "unwraps" the object. So all the keys from `myObject` will be unwrapped into `merged`, and the same will be done for `myOtherObject`. The result is that `merged` holds: `{a: '1', b: '2', c: '3'}`.

Those two tricks are very useful since React props are passed down as objects. So we can easily extract props or merge them.

References:

-   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
-   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax#spread_in_object_literals

---

## React

React is a framework that allows developers to create Components with their own lifecycle.

It greatly eases development and exposes multiple techniques to achieve results quickly.

It can be used in JavaScript, but also in TypeScript (which is what the `Admin-UI` repository uses).

For instance, a React component would look like this:

```ts
export interface IComponentOfSomeSortOwnProps {
  someProp: string;
}

export function ComponentOfSomeSort: React.FunctionComponent<IComponentOfSomeSortOwnProps> = ({
  someProp
}) => {
  return <div>{someProp}</div>;
}
```

This component can be used like the following:

```html
<ComponentOfSomeSort someProp="hello"></ComponentOfSomeSort>
```

Which would render this:

```html
<div>hello</div>
```

The interface defines the properties that can be passed down to the component, and the component returns a "template" that uses those props.

There are a lot of different concepts with using React, but you get the basic gist of what it does.

You can learn more here:

-   https://fettblog.eu/typescript-react/
-   https://reactjs.org/tutorial/tutorial.html (note that this tutorial uses _Class_ component, which is a different syntax than _Function_ components. We prefer Function components in the `Admin-UI`)
-   https://www.youtube.com/watch?v=w7ejDZ8SWv8

## React Hooks

"Hooks" in React are used to make components reactive to something specific. They use the `use` prefix, such as `useState` or `useMemo`.

In a function component, you might want to have a checkbox mapped to a variable. You can do it like this:

```ts
const MyCheckbox = () => {
    const [checkbox, setCheckbox] = React.useState(false);
    return (
        <div>
            <input type="checkbox" onClick={() => setCheckbox(!checkbox)} />
            <label>{checked ? 'CHECKED' : 'NOT CHECKED'}</label>
        </div>
    );
};
```

The magic `useState` here exposes an array of two values which are deconstructed into `checkbox` and `setCheckbox`.

When `setCheckbox` is called, the component re-rendered with the new value in `checkbox`, so the second label is re-rendered with the new string.

We like hooks in `Admin-UI` because it makes the components reactivity super clear.

References:

-   https://reactjs.org/docs/hooks-intro.html
-   https://www.freecodecamp.org/news/react-typescript-how-to-set-up-types-on-hooks/

## What the heck is a HOC?

It stands for Higher-Order Components. The basic idea is to wrap a component constructor to enhance it in some way.

For instance, `withDirtyInputHOC` can be used with an `InputConnected` component to add "dirty" tracking functionality to it.

So with the following:

```tsx
const InputWithDirty = withDirtyInputHOC(InputConnected);

const Component = () => {
  return <InputWithDirty ...>;
}
```

You can now use the input and it will store its "dirtiness" in the global state.

References:

-   https://reactjs.org/docs/higher-order-components.html

---

## Redux

Oh boy, redux.

Redux is a _state management_ library. You can think of it as a _global state_ for your application.

But wait, is global stuff bad, you tell me. Usually, yes, but in the context of Redux, not everything has access to the global state, and only _some_ things can interact with it.

There are a couple of key concepts to know about:

-   **Actions**: Something that the app can do that will modify the state in some fashion.
-   **Reducers**: They interpret an action, and change the state in some way by creating a modified copy of it.
-   **Selectors**: They take the state and exposes a sub-part of it for the app to consume.

Let's have a quick example with the following state to simulate a refresh on a page:

```ts
interface MyState {
   isLoading: boolean;
   data: {(not important)}
}
```

In the end, this makes an app with the following loop:

1. The user clicks on a button that refreshes the page: A refresh **action** is dispatched to set the loading and a request is sent to the server.
2. The **reducer** takes the action and determines that the new state is `{isLoading: true}`.
3. The application reads back the `isLoading` using a **selector** and renders a loading.
4. The requests comes back, we send an action to update the data and set the loading back to `false`
5. The **reducer** takes the action and determines that the new state is `{isLoading: false, data: (some data)}`.
6. The application reads back `isLoading` and `data`, renders the actual data in some way.

The important thing to notice here is that _anything on the page can listen to the loading, or the data_. This is very useful, since we now have a single reference to the data, and a single way to access it as well!

## React-Redux

Please read this only once you understand React and Redux 🙏

### What is this `mapStateToProps` nonsense (DEPRECATED)

The way `react-redux` works is by _injecting props from the redux state_. It will properly mix `redux` and `react`'s lifecycles so that when a state change happens, the React components will be re-rendered.

The `mapStateToProps` function looks like it says nothing, but it actually explains exactly what it does: It maps something from Redux's global state to React props.

You then use `connect` to hook the `mapStateToProps` method to your component. Maybe it makes more sense with a simple example:

```tsx
const isLoadingSelector = (globalState) => globalState.isLoading;
const dataSelector = (globalState) => globalState.data;
const mapStateToProps = (globalState) => ({
    isLoading: isLoadingSelector(globalState),
    data: dataSelector(globalState),
});

export interface ComponentOwnProps {
    someProp: string;
}

export type ComponentStateProps = ReturnType<typeof mapStateToProps>;

const ComponentDisconnect: React.FunctionComponent<ComponentOwnProps & ComponentStateProps> = ({
    someProp,
    data,
    isLoading,
}) => {
    return isLoading ? (
        <Loading />
    ) : (
        <div>
            {someProp} {data}
        </div>
    );
};

export const Component = connect(mapStateToProps)(ComponentDisconnect);
```

Phew, I know there are a lot of new concepts. Let's go over them:

`isLoadingSelector` is a selector for a part of the state. Usually, selectors are located in their own file. We use them to _select_ a part of the state and hide its real location in the global state.

`mapStateToProps` is a function that takes the global state and maps the `isLoading` and `data` props. Those will be injected in our component

`type ComponentStateProps = ReturnType<typeof mapStateToProps>;` creates a type that holds all the props that are mapped by the `mapStateToProps` function. `ReturnType<typeof mapStateToProps>` is used to extract the _ReturnType_ of the `mapStateToProps` variable. It's a neat trick to avoid redefining the props by hand.

`ComponentDisconnect` then can receive both their "Own" props (`someProp` in this case) **and** the props from the `mapStateToProps` function. This is why we can use `data` and `isLoading` in the component.

`connect(mapStateToProps)(ComponentDisconnect)` does all the magic. Without this, the `ComponentDisconnect` would not receive the `isLoading` and `data` according to Redux's state management.

`const Component` is what we call a "Connected" component since it is connected to the Redux's global state. In the `Admin-UI`, we usually only export the "Connected" version, so we keep the name short and instead add `Disconnect` to the components that is not yet connected.

So this whole thing makes it so the "Disconnect" component has _no idea that the props come from Redux_. They are just simple props that are updated each time the global state changes.

It makes it very easy to change what data the component actually needs at any time, and it can access everything _from the whole app_. Neat!

#### What is this `mapDispatchToProps` gibberish (DEPRECATED)

In Redux lingo, **dispatching** an action means changing the state in some way.

So while it seems like it says nothing, `mapDispatchToProps` actually _maps_ some _dispatch_ to React props.

Let's start with an example this time:

```tsx
const mapDispatchToProps = (dispatch) => ({
    doSomething: () => dispatch(SomeActions.action()),
});

export interface ComponentOwnProps {
    someProp: string;
}

export type ComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

const ComponentDisconnect: React.FunctionComponent<ComponentOwnProps & ComponentDispatchProps> = ({
    someProp,
    doSomething,
}) => {
    return <Button onClick={() => doSomething()} />;
};

export const Component = connect(null, mapDispatchToProps)(ComponentDisconnect);
```

The `mapDispatchToProps` takes a `dispatch` function as a parameter. This is used to **dispatch** an action to the global state.

`SomeActions.action()` is an imaginary function that returns an action to dispatch. So `dispatch(SomeActions.action())` would dispatch this action to the state.

The same `connect` function is executed, but the `mapDispatchToProps` is the second parameter instead, which provides **the `doSomething` prop** (this is the important part).

So this whole thing allows our component to interact with the global state _without knowing the props comes from Redux_.

### References

-   https://www.youtube.com/playlist?list=PLoYCgNOIyGADILc3iUJzygCqC8Tt3bRXt
-   https://www.youtube.com/watch?v=93p3LxR9xfM&t=1s
-   https://react-redux.js.org/introduction/basic-tutorial

## Other tips and tricks

Explore the `Admin-UI` code. There is no better way to learn about it than running the code and interacting with the components.

Add some `console.log("gael")` in your code and see when they pop up! But there are better ways, here are two very neat extensions that you can use:

## Redux Devtools

https://github.com/reduxjs/redux-devtools

I can't stress enough how useful is this extension.

It allows you to see all the actions that are executed by a _running app_. For instance, if you click on a button, you can see the latest action executed and how it changed the global state.

You can even go step-by-step and revert to previous states. This is actions debugging made easy

## React Devtools

https://github.com/facebook/react/tree/master/packages/react-devtools

I can't stress enough how useful is this extension (yes).

It allows you to see the elements as they were rendered by React. You can use the `Select element` and it will show you the exact location of this component and its original name (as opposed to the `Elements` table that only show `div`s and `span`s).

You can even make it so re-renders highlight the components that were redrawn, or open the profiler, which makes it very useful to debug performance issues.

## React Query

[Official documentation](https://tanstack.com/query/v4/docs/react/overview)

React Query is a powerful library allowing asynchronous state management for TS/JS, React, Solid, Vue and Svelte. It offers a collection of hooks for fetching, caching and updating asynchronous data in React.

The [Devtools](https://tanstack.com/query/v4/docs/react/devtools) for React Query are available in the Admin UI. By default, they are only available when running the app in development mode (locally).

1. Start the app locally with **pnpm start**
2. In the browser, press **Cmd+Shift+D** or **Ctrl+Shift+D**
3. You should see the React Query logo appear at the bottom of the screen. Click on it to toggle the devtools panel.
4. By using the command in #2, you can toggle the visilbity of those devtools as you like.

![](./images/react-query-devtools.png)

## Conclusion

Hope you learned a bit through this tutorial. Head to the **admin-ui-guild** Slack channel if you have any question.
