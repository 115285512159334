---
title: 'Tests (work in progress)'
metaTitle: 'Admin-UI Docs | Tests'
metaDescription: 'A guide on how to run tests in Admin-UI'
---

> WORK IN PROGRESS
>
> -   place the `The definitions of each type of test that we are going to use` & `Define when we should use each test and what we should test in it` parts of [ADR-003](https://github.com/coveo/admin-ui/pull/4817/files) here.
> -   add a paragraph on ADR-003

All packages are using the [Jest Testing framework](https://jestjs.io/).

Run the complete test suite to ensure your local `admin-ui` build is healthy and does not introduce any unwanted regressions. You'll need to have chrome installed on your machine.

```bash
pnpm test
```

Under the hood `pnpm test` from the package.json at the root of the project run this command:

```json
"script": {
  ...
  "test": "turbo run test --concurrency 1",
  ...
}

// Execute all the unit tests, in all the packages that have changed since master. --workspace-concurrency is set to 1 to avoid melting CPUs and RAM (https://pnpm.io/cli/recursive#--workspace-concurrency)
```

> Note: the performance of some of the older tests are not optimal, so the operation might take some time. For this reason, we suggest that you run the tests for the project that you're working on (if you're confident that you did not break anything else). Jenkins will run all the necessary tests.

```bash
cd path/to/package
pnpm test
```

In the package.json file at the root of all packages, `pnpm test` run this command:

```json
"script": {
  ...
  "test": "build-scripts test --maxWorkers=65%",
  ...
}
// --maxWorkers is set at 65% to maximize performance (https://jestjs.io/docs/cli#--maxworkersnumstring)
```

We provide a command to re-run the tests when you change something:

```bash
cd path/to/package
pnpm test:watch
```

In the package.json file at the root of all packages, `pnpm test:watch` run this command:

```json
"script": {
  ...
  "test:watch": "build-scripts test --watchAll",
  ...
}

// https://jestjs.io/docs/cli#--watchall
```

We provide a command to debug the tests in a specific package:

```bash
cd path/to/package
pnpm test:debug
```

In the package.json file at the root of all packages, `pnpm test:debug` run this command:

```json
"script": {
  ...
  "test:debug": "build-scripts --inspect-brk test --runInBand",
  ...
}

// Execute all the unit test in debug mode in the current package
```

_We also suggest that you read this [document](/tests/testing-ui) to know how to write good unit tests with React Testing Library._
