---
title: 'Setting up SSL on your local machine'
metaTitle: 'Admin-UI Docs | Announcements > Setting up SSL on your local machine'
metaDescription: ''
---

Over the past week we've set up a local configuration to use SSL on our **@apps/platform**, **@apps/commerce-hub** & **@apps/knowledge-hub** applications.

This feature is handy for testing OAuth2 flows in the source section on your local machine.

Here's the procedure for upgrading your installation, [which is already described in our installation procedure](/getting-started/01-configuring-your-machine).

## Update your hosts file

### on Linux & OSX

Edit `/etc/hosts` with admin rights and add this line.

```bash
127.0.0.1 local.cloud.coveo.com
```

### on Windows

Edit `C:\Windows\System32\drivers\etc\hosts` with admin rights and add this line.

```bash
127.0.0.1 local.cloud.coveo.com
```

## Create a development SSL certificate

To create a development SSL certificate, we'll use the [mkcert](https://github.com/FiloSottile/mkcert) project.
This cross-platform project is easy to use. [Please follow the installation instructions for your operating system.](https://github.com/FiloSottile/mkcert?tab=readme-ov-file#installation)

Open a terminal (use PowerShell or cmd with Windows)

```bash
# create your `.cert` folder at the root of the project
mkdir .cert
# create your certificate and key in the same file
mkcert -key-file .cert/_cert.pem -cert-file .cert/_cert.pem 'local.cloud.coveo.com'
```

## Enable the use of SSL in our applications via an environment variable.

```bash
# for @apps/platform
echo 'USE_SSL=true' > ./apps/platform/.env.local
# for @apps/commerce-hub
echo 'USE_SSL=true' > ./apps/commerce-hub/.env.local
# for @apps/knowledge-hub
echo 'USE_SSL=true' > ./apps/knowledge-hub/.env.local
```

## Start your applications

```bash
pnpm start @apps/platform @apps/commerce-hub @apps/knowledge-hub
```

Applications are automatically launched on urls:

-   [https://local.cloud.coveo.com:9000](https://local.cloud.coveo.com:9000) for Platform
-   [https://local.cloud.coveo.com:9001](https://local.cloud.coveo.com:9001) for CommerceHub
-   [https://local.cloud.coveo.com:9002](https://local.cloud.coveo.com:9002) for KnowledgeHub
