---
title: 'Creating packages'
metaTitle: 'Admin-UI Docs | Learn the basics > Creation of packages'
metaDescription: 'Creating packages in Admin-UI project'
---

This guide will help you create a new package inside `@core`, `@components` and `@pages`.
You can find in the [ADR-002](https://github.com/coveo/admin-ui/blob/master/docs/adr/002-architecture-package.md#decision-outcome) additional information regarding these folders.

## When to Use It

-   Create a package that render visual portion of the application (e.g. Data Health, Home, etc.)
-   Create a unit of logic that renders something visual (e.g. EmptyState, Navigation) that can be reused
-   Create core logic essential for the whole application (User, License, Routes, etc.)

For more information on which type of package to create, see [ADR-002](https://github.com/coveo/admin-ui/blob/master/docs/adr/002-architecture-package.md#decision-outcome)

## Steps:

1.  from your IDE or terminal (within the admin-ui repo), simply run the script below:

    `pnpm run make:package`

    This will start the `createPackage` script that will do all of the necessary work for the creation of your new package.

2.  Select the type of your package between **components**, **core** and **pages**

    ![](./images/creating-packages-step1.png)

3.  Create a name for your package from the prompt. The name have to respect the `kebab-case` and cannot contain `jsadmin` prefix

Thats it!

This will automatically create the folder within the `/pages`, `/core` or `/components` folder depending on the choice you made, with all the boilerplate code needed. It will additionally install all the dependencies from your newly made package.

![](./images/creating-packages-step2.png)
