import {Anchor, ExternalAnchor} from '@components/mantine';
import {Link} from '@tanstack/react-router';
import isAbsoluteUrl from 'is-absolute-url';
import {FunctionComponent, ReactNode} from 'react';

export type AnchorTagProps = {
    children: ReactNode;
    href?: string;
};

export const AnchorTag: FunctionComponent<AnchorTagProps> = ({children: link, href, ...props}) => {
    if (!link) {
        return null;
    }

    if (isAbsoluteUrl(href ?? '')) {
        return <ExternalAnchor href={href}>{link}</ExternalAnchor>;
    }

    if (href?.startsWith('#')) {
        return (
            <Anchor component={Link} {...props} hash={href}>
                {link}
            </Anchor>
        );
    }
    return (
        <Anchor component={Link} {...props} to={href}>
            {link}
        </Anchor>
    );
};
