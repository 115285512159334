import {Button, Header, Text} from '@components/mantine';
import {doLogout} from '@core/authentication';
import {createFileRoute, Navigate} from '@tanstack/react-router';
import {useEffect} from 'react';
import {useAuthentication} from '../auth';
import {BACKGROUND_COLORS} from '../components/splash-page/Colors';
import {SplashPage} from '../components/splash-page/SplashPage';

export const Unauthorized = () => {
    const {canAccess} = useAuthentication();
    useEffect(() => {
        document.body.classList.toggle('splash', !canAccess);
    }, [canAccess]);

    if (canAccess) {
        return <Navigate to="/" search={undefined} params={undefined} />;
    }

    const rand1 = Math.floor(Math.random() * 11).toString();
    const body = document.querySelector('body') as HTMLElement;
    body.style.setProperty('--delay', rand1);
    return (
        <SplashPage>
            <Header variant="secondary" description="You don't have the required privileges to view this page">
                Unauthorized
            </Header>
            <Text>You can try to switch to a different user.</Text>
            <Button onClick={() => doLogout()}>Switch user</Button>
        </SplashPage>
    );
};

export const Route = createFileRoute('/unauthorized')({
    beforeLoad: () => {
        if (!('registerProperty' in window.CSS)) {
            const initialColor = Math.floor(Math.random() * BACKGROUND_COLORS.length);
            for (let step = 0; step < Object.keys(BACKGROUND_COLORS[0]).length; step++) {
                document.body.style.setProperty(
                    `--myColor${step + 1}`,
                    BACKGROUND_COLORS[initialColor][`--myColor${step + 1}`],
                );
            }
        } else {
            document.body.style.setProperty('--delay', Math.floor(Math.random() * 11).toString());
            document.body.style.setProperty('--duration', '24s');
        }
    },
    component: Unauthorized,
});
