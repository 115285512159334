---
title: 'Debug bar'
metaTitle: 'Admin-UI Docs | Announcements > Debug Bar'
metaDescription: 'The Admin-UI project is delivered with a debug bar available in each application'
---

During the November 2023 Innovate session, our team ([Yann Prou](https://github.com/toofff), [Gaël Dostie](https://github.com/gdostie) and [Antoine Boissinot](https://github.com/aboissinot-coveo)) [created a debug bar for the Admin-UI project](https://innovate.internal.coveo.com/#/season/8/hack/199).

This debug bar is designed to be easy to use and as low-maintenance as possible.

The main features of this debug bar are:
- it provides a simple interface with some of our internal tools (@core packages)
- it's made in React with its own CSS so that applications don't interfere with it
- it is available in every application of the Admin-UI project
- available locally by adding an environment variable in an .env.local file at the root of each application package
- available in our pull request demos

After several months of development to make this Innovate project more sustainable, we're proud to present our debug bar.

[More information on "Debug bar"](/getting-started/04-development-tools#our-debug-bar)

![Debug bar in the Platform application](./images/debug-bar-platform.gif)

And a special mention to [Germain Bergeron](https://github.com/GermainBergeron) for finding a solution to make our pull request demos available.