---
title: '@core/organization'
metaTitle: 'Admin-UI | Core - Organization package'
metaDescription: 'A deeper look into this core package'
---

This package contains the organization currently loaded in the application.

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/organization@*"

or

pnpm add "@core/organization@*" --recursive --filter <your-package>
```
