---
title: 'Troubleshooting'
metaTitle: 'Admin-UI Docs | Getting started > Troubleshooting'
metaDescription: 'We offer simple solutions to problems you may encounter while using Admin-UI project'
---

## Vite troubleshooting

See [Rollup's troubleshooting guide](https://rollupjs.org/troubleshooting/) for more information too.

See [Vite's troubleshooting guide](https://vitejs.dev/guide/troubleshooting) for more information too.

### Dev server not starting

If you are using Linux, file descriptor limits and inotify limits may be causing the issue.
As Vite does not bundle most of the files, browsers may request many files which require many file descriptors, going over the limit.

To solve this:

- Increase file descriptor limit by `ulimit`
    ```bash
    # Check current limit
    ulimit -Sn

    # Change limit (temporary)
    ulimit -Sn 10000 # You might need to change the hard limit too

    # Restart your browser
    ```

- Increase the following inotify related limits by `sysctl``
    ```bash
    # Check current limits
    sysctl fs.inotify

    # Change limits (temporary)
    sudo sysctl fs.inotify.max_queued_events=16384
    sudo sysctl fs.inotify.max_user_instances=8192
    sudo sysctl fs.inotify.max_user_watches=524288
    ```

If the above steps don't work, you can try adding `DefaultLimitNOFILE=65536` as an un-commented config to the following files:
- /etc/systemd/system.conf
- /etc/systemd/user.conf

For Ubuntu Linux, you may need to add the line `* - nofile 65536` to the file `/etc/security/limits.conf` instead of updating systemd config files.

### Browser extensions

Some browser extensions (like ad-blockers) may prevent the Vite client from sending requests to the Vite dev server.
You may see a blank screen with no errors logged in the console, and error codes `ERR_BLOCKED_BY_CLIENT` may appear on certain requests in the network tab of your browser's console.
Ad-blockers will slow down the initial load of the applications in a major way. You should make sure it doesn't run on localhost.

Try disabling extensions if you have this issue.
