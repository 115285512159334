---
title: 'E2E Certifier'
metaTitle: 'Admin-UI Docs | Announcements > Certifier'
metaDescription: ''
---

As you may already know, the development of all applications residing inside the `admin-ui` repository, including its newest hyped member the Commerce Merchandizing Hub, is done following the continuous delivery (CD) approach. This approach has many benefits in terms of alignment with every backend services team, and development velocity. Ask anyone working on the project, and they will tell you they would never go back to manual releases, its a real game changer. However, it brings its load of challenges with respect to bug prevention. We won't go through all the preventive measures we already have in place in this post. Let's just mention that lately, a few post-mortems highlighted that our CD process was in need of an additional layer of security.

# Introducing 🥁 the new ✨E2E Certifier✨

From now on, end-to-end (Playwright) tests containing the tag `@certifier` will be run during each deployment automatically.

Those tests will act as an actual deployment pipeline certifier between `dev` environment and `stg` environment. Practically, this means the `@certifier` end-to-end tests **will not** block deployments to the development environment, allowing for quick feedback loop during development. On the other hand, a failing `@certifier` test **will** block the deployment from continuing to `stg` and subsequent environments (`prod` and `hipaa`).

# Q&A

## What if an urgent fix needs to reach production and an unrelated test blocks the deployment?

The E2E certifier is considered a "team certifier". This means anyone with access to our deployment pipeline runs in Spinnaker as the necessary privileges to bypass it. Do it responsibly and with good intent, it is also a good practice to communicate when that happens. You can do so in the `#admin-ui-builds` Slack channel or the `#admin-ui-guild` if you prefer. It should remain an exceptional measure, not become a common practice.

## Who decides what test is an acceptable `@certifier`?

In an ideal world, we would want all tests to be certifier tests. Realistically, we want to keep the deployment acceptably quick, while protecting the most important features. To reach that balance, we have established measurable [requirements](/tests/02-playwright-guidelines#certifier-requirements) that work in that sense.

## How can I add a `@certifier` test?

We have explained it in detail [here](/tests/02-playwright-guidelines#adding-a-@certifier-tag), but it is as simple as adding `@certifier` to the test title and opening a pull request.

## Why block the stg environment and not prod?

Our setup is currently working best when running against the dev endpoint. So it was simpler for us to do it this way as a starting point. Eventually we would like to run the end-to-end tests against the stg environment and block the deployment to prod when it fails.

## Who will own the `@certifier` tests?

Each team is responsible to protect the features that they own with end-to-end tests, so it only makes sense that the same team is responsible for a owning the failure of their e2e tests. That being said, general ownership mindset does apply here: when you break something, you fix it if possible. Use your judgement!

## How will we be notified of failures

-   We have a mechanism that generates Slack notifications for the tests that your team owns. [Learn how you can set this up for your team](https://github.com/coveo-platform/admin-ui/tree/master/e2e#getting-slack-notifications-for-specific-tests).
-   Deployment failures also generate notifications in the `#admin-ui-builds` channel. The frontend-foundation already monitors this channel, but feel free to keep an eye on it at your will.
