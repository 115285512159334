import {createFileRoute} from '@tanstack/react-router';
import {MdPage} from '../../components/MdPage';
import {getMdComponent} from '../../getMdComponent';

const ArticleComponent = () => {
    const Article = Route.useLoaderData();
    if (Article) {
        return <MdPage {...Article} />;
    }
    return null;
};

export const Route = createFileRoute('/_auth/$section/$id')({
    loader: async ({params: {section, id}}) => getMdComponent(section, id),
    component: ArticleComponent,
});
