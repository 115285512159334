import {Loader} from '@components/mantine';
import {createRootRouteWithContext, Outlet} from '@tanstack/react-router';
import {ReactNode, Suspense, lazy} from 'react';
import {AuthenticationContextType, useAuthentication} from '../auth';

const DebugBar = lazy(async (): Promise<{default: () => ReactNode}> => {
    if (import.meta.env.VITE_USE_DEBUG_BAR?.toLowerCase() !== 'true') {
        return Promise.resolve({default: () => null});
    }

    const module = await import('@components/debug-bar');
    return {
        default: () => (
            <module.DebugBar
                toolsConfiguration={['OwnershipTool', 'TanstackReactQueryTool', 'TanstackReactRouterTool']}
            />
        ),
    };
});

const RootLayout = () => {
    const {canAccess} = useAuthentication();
    return (
        <>
            {canAccess === undefined ? <Loader /> : <Outlet />}

            <Suspense>
                <DebugBar />
            </Suspense>
        </>
    );
};

export interface MyRouterContext {
    auth: AuthenticationContextType;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
    component: RootLayout,
});
