---
title: Admin UI Quality Commitment
metaTitle: 'Admin-UI Docs | Announcements > Quality Commitment'
---

As the R&D culture is changing, we are committing (pun intended) to improve the quality of the Admin UI repository. As such, we are sharing what is already at work, and what we can all do to help improve the quality of the Admin UI quickly and efficiently.

## What's Already in the Works from the ADUI Core Team

-   We have recently been granted a production environment for Sentry. We are actively working to resolve any issues that arise in production, ensuring that previously unknown errors in the admin UI are addressed.
-   We are currently working on migrating from Mantine 6 to Mantine 7. This migration aims to improve the performance of the app and provide better theming customizations for components, reducing the need for ad-hoc custom styles during feature implementations.
-   We are planning the end-of-life for `@coveord/plasma-react`. This will help us address any inconsistencies in development patterns. Dropping this dead weight will not only reduce maintenance efforts considerably, but also remove a lot of visual inconsistencies.
-   We will actively participate in the Opal project initiated by the UX team. This project aims to standardize styles across all Coveo products, including the website, marketing materials, UI components, and Figma components. This will help keep every design decision in line with the brand identity across all features, ultimately reducing massively the amount of back and forth between UXs, Devs, QAs, and PMs.
-   We are working towards implementing a blocking certifier based on end-to-end tests. This certifier will run before deploying the app to production, ensuring that critical issues are caught before they impact users.
-   We are planning to completely remove the UI feature-flags and focus solely on Launch-darkly flags. This way, all the feature-flags reside at the same terraform folder of our codebase along with having a better flexibility on how we want to leverage them, based on properties other than _user wise_ (organization, environment & more).

## Developer's Guide to Maintaining the Admin UI Quality

To ensure a high-quality admin UI experience, developers should consider the following elements:

-   Limit the usage of custom styling. If a component doesn't match the style specified in the mock-up, avoid trying to reproduce it with custom rules. The Alignment between mock-ups and implementation needs to be reached at the theme level. Otherwise, each custom rule creates an inconsistency that is hard to keep track of and slows down maintenance. In doubt reach out to the [#support-design](https://join.slack.com/share/enQtNjY1ODI4NjM3NTE3NC1kMjlmYWE5YmExNjAzZDQ0NzJjZDYwMzJmYWQyMThjMTQwZDljZDMyYWQ2ODlkMjQ4ZDc4MTNjYTRiNmVhN2Ez) Slack channel.
-   Protect features that are important for our users with automated tests. Core changes cannot realistically be tested manually each time for every feature of the app. We are not done with changes that impact the entire app, in fact, we never will be.
-   Involve the product manager (PM) and UX team in testing your feature. It's important to have individuals who understand the intended functionality and design of your feature test the demo branch of your pull request, not just developers.

Following these guidelines will helps to keep a high level of quality in the admin UI code and reduce the friction while maintaining the codebase.

## The importance of team work

There is no way we can achieve top quality in the Admin UI without all of you. So we owe a special thanks to all the contributors who, through their hard work, make the Admin UI a tool we cannot live without. The Admin UI core team welcomes all suggestions to improve the quality of the application and the quality of life for all developers, so reach out !
See you in your next Pull Request Review!
