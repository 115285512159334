import {Anchor, Title} from '@components/mantine';
import {Link} from '@tanstack/react-router';

import classes from './Heading.module.css';

const appendString = (children) => {
    if (!Array.isArray(children)) {
        return children;
    }

    return children.reduce((acc, current) => {
        if (typeof current === 'string') {
            return acc.concat(current);
        }

        if (typeof current === 'object') {
            return acc.concat(current.props.children);
        }

        return acc;
    }, '');
};

export const Heading = ({children, order, ...props}) => {
    const id = appendString(children)
        .replace(/[\s"'?\.]+/g, '-')
        .toLowerCase();

    return (
        <Title order={order} id={id} {...props}>
            <Anchor component={Link} hash={id} inherit className={classes.anchor}>
                {children}
            </Anchor>
        </Title>
    );
};
