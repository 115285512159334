---
title: 'Continuous Delivery'
metaTitle: 'Admin-UI Docs | Getting started > Continuous Delivery'
metaDescription: 'Explains how Continuous Delivery works and best practices'
---

## Introduction

As of November 1st 2022, Continuous delivery was enabled for the Admin-UI project.

This means any change merged in the master branch will trigger a deployment in the development, staging, production and HIPAA environment automatically, as long as all certifiers pass.

This change to the deployment process will reduce the number of bugs, make it possible to ship faster and reduce the complexity of the deployments.
The R&D units will have more power over what and when they deliver features and bugfixes.

## The continuous integration process

Here's a simple diagram to illustrate part of our continuous integration process.

![continuous integration](./images/continuous-delivery-ci.png)

### Demo app

The demo app is a tool that allows you to test your code before merging it to the target branch.
It is built with your modifications and uses the APIs of the development environment.
This allows you to test your code in a real environment, with real data, before merging it.

As soon as you work in a package that can be used by one of our applications, we automatically build the necessary demo applications.

![demo app comment in your pull request](./images/continuous-delivery-ci-demo-app.png)

### Static analysis

The static analysis is a step in our CI process that helps avoid merging code that doesn't meet quality requirements.
We run the TypeScript compiler, ESLint verification and Terraform verification.

### Tests

During this stage we launch the various unit and component tests for each package.

### Compliance Check

The compliance check is a step in our CI process that helps avoid delays and improve efficiency when rolling out code.

According to the deployment pipeline [guidelines](https://coveord.atlassian.net/wiki/spaces/CM/pages/1786708612/Package+Rollout+Certifier#Jira-issue-fields-to-validate), the **QA Testable** and **Requires Public Documentation** fields should be set with the right value in your JIRA tickets.

If **QA Testable** is set to **Yes**, or if both fields are **left empty**, your pull request will be on hold until the issue is tested and the documentation field is set to either **Done** or **No**.
**The sooner those fields are set, the better!** If you already know about testing or documentation requirements, you can always set the values in JIRA before creating your pull request.

A **compliance check** is integrated into pull requests to prevent merging code that does not meet those requirements. This check must be executed at least once per pull request, and redone on every new commit.
To execute it, click the Compliance checkbox in the pull request description under the Compliance section.

![Compliance check](./images/continuous-delivery-ci-compliance-check.png)

When the check fails, you can edit the JIRA fields with the right values or use the **demo tested** label mentioned above.

![Compliance check](./images/continuous-delivery-ci-compliance-check-error.png)

Once fixed, you can click the Compliance checkbox to re-run the compliance check.

Potential errors include the following:

- **The link with a JIRA ticket**

    To find your JIRA ticket, you must enter the ticket number either in the branch or in the title of your request.
    [More information on "JIRA issue discovery"](https://coveord.atlassian.net/wiki/spaces/CM/pages/1786708612/Package+Rollout+Certifier#Jira-Issue-Discovery).

- **The QA Testable field in your JIRA ticket**

    One of your reviewers must add the label `demo_tested` after having successfully tested your demo.

- **The Requires Public Documentation field in your JIRA ticket**

    A JIRA task from the Documentation team is linked to yours.
    You need to work with the Documentation team to ensure that the public documentation is merged before your pull request.

## The continuous deployment process

Here's a simple diagram to illustrate part of our continuous deployment process.

![continuous deployment](./images/continuous-delivery-cd.png)

> Note: The main steps in the deployment process and any errors will be communicated in the [**#admin-ui-builds**](https://coveo.slack.com/archives/CT3DGPG8H) Slack channel.

### Cancel current deployment

When a deployment is in progress, it will be automatically cancelled if a new deployment is triggered.
At that point, a new package will be generated which will include all the commits of the previously merged master branch.

So if your deployment is cancelled, don't worry, a new deployment will be automatically triggered.

### Build & deploy package

These last steps are the most important in our continuous deployment process.

They will package each application and launch deployments via [Spinnaker](https://spinnaker.io) in our deployment pipeline process.

## The deployment pipeline process

Here's a simple diagram to illustrate part of our deployment pipeline process.

![continuous deployment](./images/continuous-delivery-cd-deployment.png)

> Note: The main steps in the release process and any errors will be communicated in the [**#admin-ui-builds**](https://coveo.slack.com/archives/CT3DGPG8H) Slack channel and also in the [**Coveo DevPortal in the Admin-UI project**](https://devportal.dep.cloud.coveo.com/catalog/default/component/admin-ui/deployments).

### E2E tests certifiers

The end-to-end (Playwright) tests containing the tag `@certifier` will be run during each deployment pipeline automatically.
Those tests will act as an actual deployment pipeline certifier between `dev` environment and `stg` environment.

Practically, this means the `@certifier` end-to-end tests **will not** block deployments to the development environment, allowing for quick feedback loop during development.
On the other hand, a failing `@certifier` test **will** block the deployment from continuing to `stg` and subsequent environments (`prod` and `hipaa`).

[More information on "End-to-End tests Certifier"](/announcements/07-e2e-certifier).

### Blocked pipeline deployment

When the deployment pipeline is blocked by a failed end-to-end test:
- deployment of demos in pull requests will still be active
- you can continue to merge your pull requests on the master branch of the project
- the development environment will continue to update itself

On the other hand, deployments to Staging, Production and HIPAA environments will be blocked.

The only ways to unblock the deployment pipeline are:
- either correct the failed end-to-end test
- correct the code that caused the test to fail
- as a last resort, you can create a pull request to skip the failed end-to-end if the test is flaky

## FAQ

### How often do we deploy?

When merging code, a workflow is started in the [GitHub actions](https://github.com/coveo-platform/admin-ui/actions).
These workflows will get queued automatically.
Once no workflow is ongoing, a new package will be deployed to development.
If end-to-end certifier pass, this package will then be deployed to staging, and all the deployment pipeline certifiers will run.
This means deployment frequency depends on how many pull requests are being merged and queued, and if all certifiers pass or not.

> Note: On average, there are 3 to 6 deployments to development per day.

### Where can I see deployments?

You can find all the deployment notifications in Slack, under the [**#admin-ui-builds**](https://coveo.slack.com/archives/CT3DGPG8H) Slack channel and also in the [**Coveo DevPortal in the Admin-UI project**](https://devportal.dep.cloud.coveo.com/catalog/default/component/admin-ui/deployments).
Each thread will show when a deployment starts and ends for every environment, up to HIPAA.
If a package rollout contains invalid JIRA tickets, it will also be shown here.

![Slack #admin-ui-builds channel - open thread](./images/continuous-delivery-slack-admin-ui-builds-channel.png)

If you prefer using JIRA, the following [filter](https://coveord.atlassian.net/issues/?filter=34517) can be used to see all packages deployed in the past month. You can open any package to see its content and state.

### What's blocking the deployment?

The one thing most likely blocking deployments are JIRA tickets with invalid values, or values that require testing.
Failing certifiers (vulnerability detected, issue in automated test, invalid dependency license) could also be the cause.
Like mentioned above, those failures will be shown in the [**#admin-ui-builds**](https://coveo.slack.com/archives/CT3DGPG8H) Slack channel.

When deployments to production are blocked, the development and staging environment will still be updating.
This means other fixes and features can be added to the blocked deployment, and will be deployed to production once everything is unblocked.

### Where is my feature?

There are several ways a feature can be tracked on JIRA, all within the ticket.

You can inspect the **Releases** field in the Details section of the ticket.
The field will go from _Development_ to _Staging_ and lastly _Production_.
![Releases](./images/continuous-delivery-jira-releases.png)

You can also inspect the **Linked issues** sections, where you will see all packages containing the ticket.
If the latest package is _Completed_, it means the issue was successfully deployed to Production.
This can also give you an idea of how many packages contains the ticket: more than one package would mean it was blocked in Staging.
![Linked issues](./images/continuous-delivery-jira-linked-issues.png)

### How can I deploy a hotfix with the Continuous delivery?

Hotfixes are a thing of the past with the Continuous delivery.
Simply merging a fix in development will trigger the workflow up to production automatically.
The [Frontend Foundation](https://coveord.atlassian.net/wiki/spaces/PENG/pages/4197777463/Frontend+foundation+-+Team) team made some improvements to the deployment in S3 and we gained a lot of speed.
Deploying a package in dev now takes about ~9mins down from the ~25mins it was taking previously.
This allows the deployment pipeline to run faster and reduce the time we wait for the packages to be available in production.

### How do I know which version of Coveo release is deployed in any environment?

You have several ways to know the different versions of Coveo depending on the environment:

1. Via the source code of the application directly available on your browser
    1. Login to the Administration Console in the environment you want to query the versions
    2. Open your browser's developer tools
    3. Open the HEAD html tag
    4. Check an import script that starts with `/admin/x.y.z/js/CoveoJsAdmin.xxx.js`, the number you will find later is the number of the release deployed for your environment
2. Via the deployment application
    1. Connect to the VPN
    2. Connect to the deployment application & filtered on the good application
        - [Platform](https://deployment-pipeline-data.dep.cloud.coveo.com/dashboard/6?product=admin-ui) application (filter: `product=admin-ui`)
        - [Commerce Hub](https://deployment-pipeline-data.dep.cloud.coveo.com/dashboard/6?product=commerce-hub) application (filter: `product=commerce-hub`)
        - [Knowledge Hub](https://deployment-pipeline-data.dep.cloud.coveo.com/dashboard/6?product=knowledge-hub) application (filter: `product=knowlkege-hub`)
        - [Docs](https://deployment-pipeline-data.dep.cloud.coveo.com/dashboard/6?product=admin-ui-docs) application (filter: `product=admin-ui-docs`)
    3. You can easily see the release number for each environment

### How can I know what are the packages versions currently deployed in any environment?

1. Login to the Administration Console in the environment you want to query the versions
2. Open your browser's developper tools
3. Look at the paths to CSS or JavaScript files in the html header
    ![Header tag for see the package version](./images/continuous-delivery-packages-versions.png)
