import {Group, Image, useMantineColorScheme} from '@components/mantine';
import {ReactNode} from '@tanstack/react-router';
import {FunctionComponent} from 'react';
import {QuickNavigation} from '../navigation/QuickNavigation';
import {DarkModeSwitch} from './DarkModeSwitch';
import logoLight from './images/Coveo_Horizontal_Blue.svg';
import logoDark from './images/Coveo_Horizontal_White.svg';

interface TopBarProps {
    children: ReactNode;
}

export const TopBar: FunctionComponent<TopBarProps> = ({children}) => {
    const {colorScheme} = useMantineColorScheme();
    return (
        <Group w="100%" h="100%" px="xl" gap="sm">
            {children}
            <Group h="100%" justify="space-between" flex="1">
                <Image src={colorScheme === 'dark' ? logoDark : logoLight} h={34} fit="contain" alt="coveo logo" />
                <Group justify="right" wrap="nowrap" gap="sm">
                    <QuickNavigation />
                    <DarkModeSwitch />
                </Group>
            </Group>
        </Group>
    );
};
