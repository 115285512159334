import {List} from '@components/mantine';

import AdminUIPackageJson from '../../../../package.json';

export const Prerequisites = () => (
    <List fz="sm">
        <List.Item>Node.js {AdminUIPackageJson.engines.node}</List.Item>
        <List.Item>pnpm {AdminUIPackageJson.engines.pnpm}</List.Item>
    </List>
);
