---
title: 'State management'
metaTitle: 'Admin-UI Docs | Learn the basics > State management'
metaDescription: 'Understanding how Admin-UI does state management and data flow'
---

Concepts that will be covered in this section:

-   Understanding what state management is for a React application
-   Introduction to React Hooks and its importance for state management
-   Introduction to React context API
-   High level overview of React Redux
-   Concepts of state, store, reducers, actions and selectors

## Introduction

The simplest way to pass data from a parent to a child in a React Application is by passing it on to the child's props. But an issue arises when a deeply nested child requires data from a component higher up in the tree. If we pass on the data through the props, every single one of the children would be required to accept the data and pass it on to its child, leading to prop drilling, a terrible practice in the world of React.

To solve the prop drilling issue we have to utilized the state, or the source of truth, of a React application. However, because the state has to be immutable, we have to use a state management solutions like the React state hooks, the Context API and Redux to update the project's state.

The Admin-UI uses a type of statement management for differents use cases. You could compared your state management choice like you choose your classes' functions or propreties accessibilility; some information should be accessible globally to your project, some of them should be accessible only to a group of components and some of them should be accessible to a single component.

## React State Hooks

Since React 16.8, it is possible to use state hooks to set and fetch part of a component's state. This feature is contained in a single component.
For more information about the state hooks, [you can refer to the official documentation](https://reactjs.org/docs/hooks-state.html).

### When We Are Using It

Included in a single component, it checks or update some part of the feature when the user is interacting with it. For example:

-   It updates a condition to hides or not something when a item is selected in a dropdown.
-   It increments a count when the user click on a button.
-   It enables a checkbox if the user clicks on a specific radio button.
-   ...

In sum, the information contains in the state will affect only a single component, and the rest of the application shouldn't be aware of this information. We strongly suggest to use this method of state management as much as possible over Redux.

### How to Use It

To update part of the component's state, you have to set the constants that contains this information, and a function that will update this information.

-   How to initialized the hooks.

```tsx
import React, {useState} from 'react';

const [count, setCount] = useState(0);
```

-   How to update the state

```tsx
const [count, setCount] = useState(0);

<Button onClick={setCount(count++)} name="click on me to increment your count" />
<p>{count}</p>
```

## Context API

Context API is a built-in React tool that does not influence the final bundle size, and is integrated by design. The context contains some information that can be share to the components that are included in the context.
For more information about the Context API, [you can refer to the official documentation](https://reactjs.org/docs/context.html).

### When We Are Using It

We use the Context API to avoid props drilling on a tree of components. The information contained in the context is relevant only to these components. Multiple panels uses this tool:

-   A table could share some information to the children components (rows, cells, headers, etc...);
-   A source panel will be able to interact between the left and right layouts' components.
-   Some information could be relevant to be known between two tabs in a component with a navigation feature.
-   ...

In summary, if a section in the Admin-UI has multiple children components, a context will facilitate the interaction between them, without exposing this information everywhere. Like the Redux state hooks, we strongly suggest to use a context over a new Redux implementation for the management of your components state.

### How to Use It

To use Context API you need to :

-   Create the Context

```tsx
import {SourceModel} from '@coveord/platform-client';

export const EditSourceContext = React.createContext<SourceModel>(null);
```

-   Create a Provider for the Context and wrap a component that will need the provider in it.

```tsx
<EditSourceContext.Provider value={source as SourceModel}>
    <EditSource
    // ...
    />
</EditSourceContext.Provider>
```

-   Consume the data of the context inside the component

```tsx
export const EditSource: React.FunctionComponent<EditSourceProps> = () => {
    const source = React.useContext(EditSourceContext);

    // ...
};
```

## Redux

Redux is a state management library. You can think of it as a global state for your application. Redux is an Open Source Library which provides a central store, and actions to modify the store. To use Redux you will need to create and implement a few parts to create your data flow.

### When we are using it

Redux is used to hold global information that could be useful multiple panels, for example:

-   The components coming from Plasma will hold some information that can be easily retrieved from the state using the id of the component implemented.
-   The user, license or privileges' information is useful accross the Admin-UI console, so it's more efficient to save this information once in the Redux state instead of fetching it on every panels.

In a nutshell, a full redux implementation is useful when the information's scope goes beyond the main panel or section implemented, and this information should be accessible everywhere.

Although Redux can be used anytime, we strongly suggest to avoid implementing new keys in the state. In most cases, a context is enough to share information to a restricted number of components.

### How to use it

In that section we will explains the core concepts of Redux and how we are using them in the Admin-UI project, so you can start contributing to the project. However, we won't do a deep dive on what is Redux and all the tools that could be used. Instead, we will list a few resources at the end of this section if you want to learn more about this state container.

### State and Reducers

As mentioned previously, the state is the source of truth of your application. Therefore, a state can be created for a specific part of your project. This state will be then linked to your reducers.

The reducers interpret an action, and change the state by creating or modified a copy of it.
For more information, [you can refer to the official documentation](https://redux.js.org/tutorials/fundamentals/part-2-concepts-data-flow#reducers)

-   How to create a reducer and the state

```tsx
import {ReducersMapObject} from 'redux';

export interface SourcesState {}

export const SourcesReducers: ReducersMapObject<SourcesState> = {};
```

-   How to update a single key

```tsx
import {KeyValueReducerGenerator} from '@coveord/jsadmin-common';

export interface SourcesState {
    value: string;
    object: ObjectModel;
    objects: ObjectModel[];
}

export const SourcesReducers: ReducersMapObject<SourcesState> = {
    value: KeyValueReducerGenerator<string>(SourcesActionTypes.setValue, null),
    object: KeyValueReducerGenerator<ObjectModel>(SourcesActionTypes.setObject, null),
    objects: KeyValueReducerGenerator<ObjectModel[]>(SourcesActionTypes.setObjects, null),
};
```

-   How to update an object key

```tsx
import {ObjectReducerGenerator} from '@coveord/jsadmin-common';

export interface SourcesState {
    objectItems: Record<string, ObjectModel>;
}

export const SourcesReducers: ReducersMapObject<SourcesState> = {
    objectItems: ObjectReducerGenerator<Record<string, ObjectModel>>(SourcesActionTypes.setObjectItems),
};
```

### Actions

An action is an event that describes something that happen in the application, triggering an update in the state.
For more information, [you can refer to the official documentation](https://redux.js.org/tutorials/fundamentals/part-2-concepts-data-flow#actions)

To indicate to the application that an event happened, the action has to be dispatched. Redux provides an hook, [useDispatch](https://react-redux.js.org/api/hooks#usedispatch), that facilitate the dispatch in the component directly.

-   How to Use the redux hook `useDispatch` to dispatch actions

```tsx
import {useDispatch} from 'react-redux';

const dispatch: IDispatch<SourcesState> = useDispatch();

// update specific key in the state
dispatch(setKeyValue(SourcesActionTypes.setValue, 'new-value'));

// dispatch an action from a Plasma component
dispatch(ModalActions.openModal('some-id'));
```

-   How to dispatch an action when interacting with a component

```tsx
const open = () => dispatch(ModalActions.openModal('some-id'));

<Button onClick={open} name="will open a modal on click" />;
```

### Store

The store is the container that manage the data flow. In other words, it makes sure that the actions dispatched are recorded and the state is updated and available accordingly.

There is only one store that hold all the information, `@core/store`, but every package has its own wrapper over the store to expose only the needed information. Here is, for example, the `SourceStore`, which expose only the `SourcesState` :

```tsx
import {getStore} from '@core/store';
import {SourcesState} from '../reducers/sourceReducers';

export const SourceStore = getStore<SourcesState>();
```

For more informations, [refer to the @core/store documentation](https://github.com/coveo/admin-ui/blob/master/core/store/README.md)

-   How to make the Store available for data consumption

```tsx
import {SourceStore} from '../../store/SourceStore';

<Provider store={SourceStore}>
    <AmazonS3FormBody
    // ...
    />
</Provider>;
```

### Selectors

The selectors expose specific part of the state for the application to consume. Redux provides an hook, [useSelector](https://react-redux.js.org/api/hooks#useselector), to use the selectors directly in the component.

-   How to use the redux hook `useSelector` to access the SourceState contained in the SourceStore

```tsx
const isSourcePaused = useSelector((state) => {
    EditSourceSelectors.isSourcePaused(state, {id: sourceId});
});
```

### Additional resources

-   [Summary of Redux core concepts](https://redux.js.org/introduction/core-concepts).
-   [Learning resources suggested by the Redux team](https://redux.js.org/introduction/learning-resources).
-   [Tools and extensions that could be added](https://redux.js.org/introduction/ecosystem).
-   [Reselect: A library for creating memoized "selector" functions](https://github.com/reduxjs/reselect).
