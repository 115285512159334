import {ActionIcon, useMantineColorScheme} from '@components/mantine';
import {MoonAndStarsSize24Px, SunSize24Px} from '@coveord/plasma-react-icons';
import {FunctionComponent} from 'react';

export const DarkModeSwitch: FunctionComponent = () => {
    const {colorScheme, toggleColorScheme} = useMantineColorScheme();
    const checked = colorScheme === 'dark';
    return (
        <ActionIcon onClick={toggleColorScheme} variant="default" size="lg" aria-label="Toggle color scheme">
            {checked ? <SunSize24Px height={24} /> : <MoonAndStarsSize24Px height={24} />}
        </ActionIcon>
    );
};
