---
title: '@core/validator'
metaTitle: 'Admin UI | Core - Validator package'
metaDescription: 'How to use Zod validation in your package'
---

Aims to provide all the tools needed to perform schema-based validation for objects or forms.
This package builds on the [**Zod**](https://github.com/colinhacks/zod) package and re-exports some of its contents.

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/validator@*"

or

pnpm add "@core/validator@*" --recursive --filter <your-package>
```

## User Guide

### Zod

This is what the introduction to the **Zod** library says.

> Zod is a TypeScript-first schema declaration and validation library. I'm using the term "schema" to broadly refer to any data type, from a simple string to a complex nested object.
>
> Zod is designed to be as developer-friendly as possible. The goal is to eliminate duplicative type declarations. With Zod, you declare a validator once and Zod will automatically infer the static TypeScript type. It's easy to compose simpler types into complex data structures.

[Go and read their documentation](https://github.com/colinhacks/zod), it's really well done and stands on its own.

### Uses with @Mantine/Form

To validate a form of type [`@mantine/form`](https://mantine.dev/form/use-form) with Zod, use the [`mantine-form-zod-resolver`](https://github.com/mantinedev/mantine-form-zod-resolver) library.

[An example in the Mantine documentation](https://mantine.dev/form/schema-validation/#zod) will certainly help you.

:warning: Mantine does not currently support asynchronous validation with Zod.

### Uses with ReactHookForm

To validate a form of type [`react-hook-form`](https://www.react-hook-form.com) with Zod, use the [`@hookform/resolvers`](https://github.com/react-hook-form/resolvers) library.

[An example in the Mantine documentation](https://react-hook-form.com/get-started#SchemaValidation) will certainly help you.

**use with asynchronous validation**

```typescript
import {zodResolver} from '@hookform/resolvers/zod';

const validationSchema = z.object({
    name: z
        .string()
        .trim()
        .min(3)
        .max(355)
        .superRefine(async (name, ctx) => {
            if (name === undefined || name === '') {
                return;
            }

            const cleanedName = name.trim().toLowerCase();
            const sources = await Platform.source.list({
                filter: cleanedName,
                stringFilterType: 'EXACTMATCH',
                perPage: 1,
            });

            if (sources.sourceModels.length !== 0) {
                ctx.addIssue({
                    message: Locales.format('NameField.error.duplicateError'),
                    code: z.ZodIssueCode.custom,
                    fatal: true,
                });

                return z.NEVER;
            }
        }),
});

const form = useForm({
    defaultValues: {
        name: '',
    },
    resolver: zodResolver(validationSchema.strict()),
});
```

**use with synchronous validation**

```typescript
import {zodResolver} from '@hookform/resolvers/zod';

const validationSchema = z.object({
    name: z.string().trim().min(3).max(355),
});

const form = useForm({
    defaultValues: {
        name: '',
    },
    resolver: zodResolver(validationSchema.strict(), undefined, {
        mode: 'sync',
    }),
});
```
