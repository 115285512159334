---
title: 'Shared ownership'
metaTitle: 'Admin-UI Docs | Learn the basics > Ownership'
metaDescription: 'Our product is owned by multiple teams. This page details how ownership is documented in the codebase, how to create issues, and how to promptly discover Sentry issues.'
---

import {FileOwnershipTable} from '../../components';

## Overview

The product ownership is shared between multiple teams. Each team is responsible for specific sections of the codebase.
Ownership details are documented directly in the code to ensure clarity and easy reference.

## Assigning the ownership

Some of our components are assignable to a team, for instance Page, Modal, ModalWizard, etc.
To document ownership directly in the code, you can add the owner and framework props to those components:

```tsx
<Modal {...props} owner="frontend-foundation" framework="Mantine">
    {children}
</Modal>
```

We made sure to make the props required on the components. You'll have autocompletion for the possible values.

## Details

The following table provides an overview of the ownership for each section of the project.
This data can be filtered and sorted as needed, and it is dynamically generated to reflect the current state of the project.

<FileOwnershipTable />

## Creating JIRA issues

To create an issue for a specific section, you can use the "Report issue" button available in the product.

![report issue](./images/report-issue.png)

This feature will automatically route the issue to the correct owner based on the section in which the issue was encountered.

## Automated Sentry assignment

We utilize Sentry for error tracking and monitoring.
Our Sentry project is configured to automatically assign errors to the appropriate teams based on the section where the error occurred.
This ensures that issues are promptly addressed by the responsible team.

Here's a handy [link](https://coveo-platform.sentry.io/issues/?project=4507181442596864&query=is%3Aunresolved+assigned%3Amy_teams&referrer=issue-list&statsPeriod=14d) to see which issues are assigned to your team

### Slack notifications

Sentry errors are automatically sent to Slack in the [#admin-ui-sentry-dev](https://coveo.slack.com/archives/C01AHG4C8EN) and [#admin-ui-sentry-prd](https://coveo.slack.com/archives/C06AQEB7GQ0) channels, but it's also possible to dispatch the errors to other channels if needed.

To customize a team's Slack notifications, open a pull request in the [infra-sentry-cloud](https://github.com/coveo-platform/infra-sentry-cloud) repository to modify the [mappings file](https://github.com/coveo-platform/infra-sentry-cloud/blob/main/infrastructure/terraform/notifications/strategies/dispatch/mappings.yaml).

You must define a default channel, and you can override the notification channel for any environments.
