---
title: 'Forms (Deprecated)'
metaTitle: 'Admin-UI Docs | Learn the basics > Forms (Deprecated)'
metaDescription: 'Form concepts with Redux, handling forms with privileges.'
---

> DEPRECATED
>
> For new UI features, please use [Mantine / Form](https://mantine.dev/form/use-form/).
>
> You can follow our Mantine implementation in the [`@components/mantine` package](https://github.com/coveo/admin-ui/tree/master/components/mantine).
> Soon, we will replace part of Plasma with Mantine components.
>
> In the meantime, we leave this documentation accessible for existing code.

Concepts that we will cover in this documentation

Here you will learn the following:

-   Form concepts with the Redux Store in Admin UI
-   TextInput vs Input (deprecated)
-   How we gather Form Data
-   How we work with the new TextInput
-   How we use validation on the TextInput

## Form concepts with the Redux Store in Admin UI

In Plasma, there is a section for Form components and these components are any items of the UI that the user interacts with. These include Buttons, Checkboxes, Radio buttons, TextInputs etc. (Please refer to <a href="https://plasma.coveo.com/" target="_blank">Plasma</a> for more examples).

Every time a user interacts with these components in the Admin-UI, they dispatch an action to update the Redux store.

![](./images/forms-flow-redux.png)

From here, selectors can be used to select the data from the Redux store and retrieving information such as the text or the state of whether a checkmark has been selected to generate the necessary data structure needed to create a JSON configuration that the backend needs to process the data.

## TextInput vs Input (deprecated)

Aside from the styling, they are fundamentally very different under the hood. The `Input` is connected to the Redux store, which is not the case for the new `TextInput`.

The reason for this change was to prevent polluting the global store in the Admin-UI application. The new `TextInput` relies on its own type of "store" (aka a [React Context](https://reactjs.org/docs/context.html)) and uses the Provider which allows the component to subscribe for context changes.

This new pattern will allow us to prevent dispatching needlessly to the global redux store and will help scope to just the component level. Another advantage is that we no longer need to always create boiler plate selectors just to get the text input and leverage the useState pattern found in `useTextInput()`.

In the future, the Tooling team will have most form components wrapped in the Form Provider so that all the Form components are scoped to their own state as seen above.

## How do we gather Form data?

We can use the Redux [useSelectors](https://redux.js.org/usage/deriving-data-selectors#encapsulating-state-shape-with-selectors) to gather information values from the Redux store. Since all the other Form components are still connected to the Redux store, this will be a common pattern for all of them except for the `TextInput` which will be explained later.

An important note to remember, every Form component has a unique ID that identifies itself, this allows developers to extract the correct Form if there are multiple of the same type.

### Selectors

For all other types of Form data, the `useSelector` is used to retrieve information such as its value or whether its valid, dirty or if something is checked and so on.

To get the value (text string) for the `Input` component, it is done right inside the component like so:

```tsx
import {InputSelectors, CheckboxSelectors} from '@coveord/plasma-react';

const getText = useSelector((state) => InputSelectors.getValue(state, {id: 'TextBoxId'}));
const isTextValid = useSelector((state) => InputSelectors.getIsValid(state, {id: 'TextBoxId'}));
const isFirstCheckmarkEnabled = useSelector((state) =>
    CheckboxSelectors.getIsSelected(state, {id: 'firstCheckmarkId'}),
);
const isSecondCheckmarkEnabled = useSelector((state) =>
    CheckboxSelectors.getIsSelected(state, {id: 'secondCheckmarkId'}),
);
```

Since this is written right inside the component, the state parameter comes from the [Provider](https://react-redux.js.org/api/provider) of the Redux store which is passed down and allows the `useSelector` to grab the necessary information and apply the `InputSelectors` and `CheckboxSelectors` to return their value needed.

## How we work with the new TextInput

Since the `TextInput` is not connected to the global redux store, we cannot use the `useSelector` function to take it from the state. We use `useTextInput` which provides the state and all the details scoped into its component, like so:

```tsx
const sourceNameInput = useTextInput('sourceInputId');
```

From here, the stored information is in the `sourceNameInput` which contains the `value`, `status`, `message` and many others that might be useful found in its [Plasma documentation](https://plasma.coveo.com/form/TextInput)

When you use the `TextInput` from the demo in Plasma, all of the dispatching is handled in the component.

To get the value of the input field, it can be accessed like so:

```tsx
const sourceNameInput = useTextInput('sourceInputId');
console.log(sourceNameInput.state.value);
```

![](./images/forms-text-input-flow.png)

This is the key difference on how we access the `TextInput` compared to the deprecated `Input`. As you can see, we no longer use the `useSelector` to get the text input value from the global store.

### Using the useFormComponent

The `useFormComponent` is a hook of the `FormProvider` that allows for a simple and easy way to gather all of the information of a specified form.

Currently, only the `TextInput` is supported and there will be support other types of Forms in the near future.

```tsx
const allTextInputs = useFormComponent('TextInput').state;
```

In this example `allTextInputs` will return an object of all the `TextInputs` that are wrapped inside the `FormProvider`.

## How we use validation on the TextInput

Validating user TextInput is an important step to ensure the user is entering data that the back-end will accept. Text can be validated using the `validate` prop of the `TextInput`

A quick example can be seen below where the TextInput is using the `validate` prop.

```tsx
import {FormProvider, TextInput} from '@coveord/plasma-react';

<FormProvider>
   <TextInput
        id="delicious-pizza-toppings"
        type="text"
        label="Best pizza topping"
        validate={(value) =>
            if (value === "pineapple") {
                return {status: 'valid'}
            } else {
                return {status: 'invalid'}
            }
        }
        showValidationOnBlur
    />
</FormProvider>
```

### Using our validators

In our Admin-UI repository, we have premade validators to use.

These can be found in the `jsadmin-common` folder.

```
admin-ui
│   └─── jsadmin-activity-browser
│   └─── jsadmin-commerce
│   └─── jsadmin-common
│       └── src
│           └── validation
│               └── EmailValidator.ts
│               └── LargeWebsitesValidator.ts
│               └── NotEmptyValidator.ts
│               └── RegexValidator.ts
│               └── ...
│
│ package.json

```

If there are validators that do not fit your specific needs, you can always add more. As a quick example of how to use the `NotEmptyValidator` in the `TextInput`, we can code it like so:

```tsx
import {FormProvider, TextInput} from '@coveord/plasma-react';
import {notEmptyValidators} from '@coveord/jsadmin-common';

<FormProvider>
    <TextInput
        id="delicious-pizza-toppings"
        type="text"
        label="Best pizza topping"
        validate={(value) => notEmptyValidators.validateInput(value, {trim: true})}
        showValidationOnBlur
    />
</FormProvider>;
```

## Handling Form components with privileges

In the Admin-UI, a user may have different privileges that allow them to edit or only view certain sections. With this in mind, its important to make sure form components are disabled from user interactions based on what privileges they have.

### Disabling Form components

`Buttons`, `Checkboxes`, `TextInput` and other form components have props that can disable the user interactions. We can control whether they are disabled or enabled based on a set of conditions or on a selector that looks for the privileges from the parent component.

A few examples can be shown below

```tsx
import {Button, CheckboxConnected, Label, TooltipPLacement} from '@coveord/plasma-react';

export const DisabledComponents: React.FunctionComponent = ({isReadOnly}) => {
    return (
        <>
            <TextInput
                id="TextInput-id"
                label="Name Label"
                showValidationOnChange
                validate={(value) => notEmptyValidators.validateInput(value, {trim: true})}
                required
                type="text"
                disabled={isReadOnly}
            />

            <Button
                id="Button-id"
                name="Save"
                primary
                tooltip={isReadOnly ? 'Not enough priviledges' : ''}
                tooltipPlacement={TooltipPlacement.Top}
                enabled={!isReadOnly}
            />

            <CheckboxConnected id="Checkbox-1" checked={true} disabled={isReadOnly}>
                <Label className="flex">Checkbox 1</Label>
            </CheckboxConnected>
        </>
    );
};
```
