---
title: 'Hotfix during code freeze'
metaTitle: 'Admin-UI Docs | Guides > Hotfix during code freeze'
---

A few times during the year we need to do a code freeze and block deployments of the admin-ui in production and hipaa environments. Even with the few code freeze we sometimes have to deploy a hotfix. Follow this guide to deploy a fix in production.

## 1. Create the hotfix branch

Because of the branch restrictions you cannot manually create the branch. You need to dispatch the [Create Hotfix Branch workflow](https://github.com/coveo/admin-ui/actions/workflows/create-hotfix-branch.yml).

1.  Open the link to the workflow
2.  Click on run workflow ![Run workflow](./images/hotfix-run-workflow.png)
3.  Enter the version currently running in production in the `tag to start from` input (e.g., `v11.1234.0`). To identify the version running in production you can look at the source code of the page on [https://platform.cloud.coveo.com/admin](https://platform.cloud.coveo.com/admin/). ![Production version](./images/hotfix-get-version.png)
4.  The workflow will run and create the branch

## 2. Create your pull request

Instead of basing your changes on master you need to start from the branch created at step 1. It will be named something like `hotfix-v11.1234.0`.

Code your changes and open a pull request. Make sure to target the `hotfix branch` and not `master`

## 3. Make sure the deployment goes to production

The CD pipeline will run for the hotfix branch. Once everything is validated, it will start a deployment for the new version (e.g., `v11.1234.1`).

The dev tooling team added a certifier that will always fail in staging during code freezes. You'll need to get someone from QA, R&D Defence, or Ops to override the approval and then restart the certifier for the deployment to continue to production and hipaa.

![Certifier](./images/hotfix-certifier-override.png)

## 4. Bring the fix in master

Don't forget to also apply your fix in master, otherwise there will be a regression once the code freeze is lifted.
