import {Box, Center, Paper, Stack} from '@components/mantine';
import {FunctionComponent, ReactNode} from 'react';
import classes from './SplashPage.module.css';
import Logo from './images/coveo-logo-new.svg';
import Diamonds from './images/diamonds.svg';
import Glow from './images/glow.svg';
import './Global.css';

export const SplashPage: FunctionComponent<{children: ReactNode}> = ({children}) => (
    <Center mih="100vh" w="100%" px={{base: 40, xs: 'xs'}}>
        <Stack align="center" justify="center" mih="100vh" mah="auto" gap="xl" className={classes.stack}>
            <Box pt="md">
                <img src={Logo} width="184px" alt="Coveo Logo" />
            </Box>
            <Paper
                maw="528px"
                w={{base: '100%', xs: '528px'}}
                px={{base: 'md', xs: 56}}
                py={{base: 'lg', xs: 48}}
                bg="gray.0"
                ta="center"
                radius="md"
            >
                <Stack h="100%" align="center">
                    {children}
                </Stack>
            </Paper>
        </Stack>
        <img className={classes.diamond} src={Diamonds} alt="background image" />
        <img className={classes.glow} src={Glow} alt="background image" />
    </Center>
);
