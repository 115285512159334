---
title: 'Using Storybook'
metaTitle: 'Admin-UI Docs | Best practices > Using Storybook'
metaDescription: 'How to use Storybook within the Admin-UI project'
---

Storybook is a frontend workshop for building UI components and pages in isolation. It streamlines component development and documentation.
It's a great tool to have in your toolbox when working on the Admin-UI project.

It's really easy to add Storybook to an existing package. Here's how you can do it:

## 1. Make sure Storybook installs your package

Add your package in the `dependencies` of [storybook](https://github.com/coveo-platform/admin-ui/blob/master/storybook/package.json) and run a `pnpm install`.

```diff
"dependencies": {
+ "@components/my-package": "workspace:*",
```

## 2. Install @storybook/react in your package

You can modify the `package.json` of your package to add Storybook as a dev dependency, or you can use the pnpm CLI.

_Make sure to install the version that is already used in the project_

```bash
pnpm -r add -D @storybook/react --filter @components/my-package
```

## 3. Create a story for your component

Inside your package, create a new file with the `.stories.tsx extension. This will allow Storybook to pick it up automatically.

You can look at the existing stories in the project to get an idea of how to write your own. Here's an example:

```tsx
import type {StoryObj, Meta} from '@storybook/react';
import {Guard} from './Guard';

const meta: Meta<typeof Guard> = {
    title: '@components/security/Guard',
    component: Guard,
};
export default meta;

type Story = StoryObj<typeof Guard>;

export const SimpleGuard: Story = {
    args: {
        canRender: true,
        children: <div>Content</div>,
    },
};
```

To run Storybook locally, use the following command:

```bash
pnpm -r storybook
```

This will start a local server, which you can access at [http://localhost:6006](http://localhost:6006).

Your story should now be visible in the Storybook UI.

## 4. Demoing your stories in a PR

There are two ways to build the Storybook demo:

1. When you create a PR that modifies a story, a GitHub action will run and build the Storybook demo.
2. If you modify a component that is used by a Storybook story, you can tick the `Click on this checkbox to build the Storybook demo` checkbox in your PR description.

Once the demo is built, GitHub Actions will automatically add a comment. You'll be able to access it the same way you access any other application demo.

![Storybook demo link](./images/storybook-demo-link.png)
