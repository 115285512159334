---
title: 'Table refactor'
metaTitle: 'Admin-UI Docs | Announcements > Table refactor'
metaDescription: ''
---

We improved the developer experience of working with the Table component from plasma-mantine, and made major changes in the following projects:

-   Admin Console
-   Merchandising Hub

Be sure to check the updated examples on the [Plasma website](https://plasma.coveo.com/layout/Table)

The table component refactor is part of [@coveord/plasma-mantine version 54.0.0](https://github.com/coveo/plasma/releases/tag/v54.0.0).
Be sure to consult the changelog to have more details about what changed compared to the previous iteration.

Reach out to us in the [#admin-ui-guild](https://coveo.slack.com/archives/C01M10DME91) if you have any questions regarding this update or if you need assistance updating to the latest plasma-mantine version in your own project.
