import {Group, InputBase, Kbd, openSpotlight, Spotlight, SpotlightActionData, Text, useOs} from '@components/mantine';
import {SearchSize16Px} from '@coveord/plasma-react-icons';
import {useNavigate} from '@tanstack/react-router';
import {FunctionComponent} from 'react';
import {getAllMdIds} from '../../getMdComponent';

export const QuickNavigation: FunctionComponent = () => {
    const navigate = useNavigate();
    const os = useOs();
    const isUsingAppleProduct = os === 'undetermined' || os === 'macos';
    const actions = getAllMdIds(null).map(
        (obj): SpotlightActionData => ({
            id: obj.id,
            label: obj.title || obj.metaTitle,
            description: obj.metaDescription,
            onClick: () =>
                obj.section === null ? navigate({to: `/${obj.id}`}) : navigate({to: `/${obj.section}/${obj.id}`}),
            group: obj.section === null ? obj.id : obj.section,
        }),
    );
    return (
        <>
            <Spotlight
                actions={actions}
                shortcut={['mod + K', '/', 'Ctrl + K']}
                nothingFound="Nothing found..."
                limit={4}
                highlightQuery
                searchProps={{
                    leftSection: <SearchSize16Px height={16} />,
                    placeholder: 'Quick navigation...',
                }}
            />
            <InputBase
                visibleFrom="sm"
                leftSection={<SearchSize16Px height={16} />}
                placeholder="Quick Navigation"
                onClick={() => openSpotlight()}
                rightSection={
                    <Group gap={4} mr="xs">
                        <Kbd size="xs" c="gray.5">
                            {isUsingAppleProduct ? '⌘' : 'Ctrl'}
                        </Kbd>
                        <Text span>+</Text>
                        <Kbd size="xs" c="gray.5">
                            K
                        </Kbd>
                    </Group>
                }
                rightSectionWidth={isUsingAppleProduct ? 58 : 76}
            />
        </>
    );
};
