export const BACKGROUND_COLORS: Array<{[key: string]: string}> = [
    {
        // grape
        '--myColor1': 'rgba(26, 7, 39, 0.82)',
        '--myColor2': 'rgba(66, 20, 88, 0.03)',
        '--myColor3': '#170622',
        '--myColor4': '#6A2388',
        '--myColor5': '#270C38',
    },
    {
        // govs
        '--myColor1': '#071140',
        '--myColor2': 'rgba(7, 17, 64, 0)',
        '--myColor3': '#071140',
        '--myColor4': '#24389E',
        '--myColor5': '#071140',
    },
    {
        // ebony
        '--myColor1': '#181D3A',
        '--myColor2': 'rgba(24, 29, 58, 0)',
        '--myColor3': '#181D3A',
        '--myColor4': '#42427D',
        '--myColor5': '#181D3A',
    },
    {
        // tropic
        '--myColor1': '#01211B',
        '--myColor2': 'rgba(1, 33, 27, 0)',
        '--myColor3': '#023128',
        '--myColor4': '#00634F',
        '--myColor5': '#043F33',
    },
];
