import {Container, Header, Stack} from '@components/mantine';
import {FunctionComponent} from 'react';
import {MdMetadata} from '../getMdComponent';

export const MdPage: FunctionComponent<Omit<MdMetadata, 'path'>> = ({metaDescription, title, Component}) => (
    <Container w="100%">
        <Stack gap={0} id="scrollcontent">
            <Header description={metaDescription} borderBottom>
                {title}
            </Header>
            <Component />
        </Stack>
    </Container>
);
