---
title: 'Guidelines for better Cloud Admin Functional Tests'
metaTitle: 'Admin-UI Docs | Tests > Guidelines for better Cloud Admin Functional Tests'
metaDescription: 'A quick guide on making better functional tests in Cloud Admin'
---

> WORK IN PROGRESS
>
> Needs to be rewritten for Cypress

How to deal with functional tests for the Coveo V2 features **as a frontend developer**.

## Why do we care about the FTs

Between the _QA_Cloud_FTs_Health_Check_Suite_ , the _QA_Analytics_FT_ and the _QA_MachineLearning_FT_ multijobs, most of the Admin-UI is tested every day in the default region (US) and in Europe.
This saves a lot of time, money and man power to be used elsewhere. Also, some of the FTs are testing parts of the Admin that are not used by the developers and/or users regularly enough to find new bugs.
​
An example is [ADUI-6045](https://coveord.atlassian.net/browse/ADUI-6045). It was caught by the FTs in different rule panels in Query Pipeline, after another bug was fixed. We were expecting everything to be green after the bugfix but we were still in the red. The option `Copy to..` in the `More Menu` of the Rule panels is something rarely used by the developper so without the FTs, this bug would likely made its way to production without us knowing.

## Documentation

Some documentation on Nightwatch, repository guidelines, Browserstack and more and be found here : [https://coveord.atlassian.net/wiki/spaces/QA/pages/215056713/Nightwatch](https://coveord.atlassian.net/wiki/spaces/QA/pages/215056713/Nightwatch)
The link to the KT presentation video and slides [ is here](https://drive.google.com/drive/folders/0AMFniPfbyHxZUk9PVA)

## Code reviews for Cloud Admin tests Pull Request

Here's a few things to look out for:

-   Clean Code (variable names, tests name, good javascript practices, etc)
-   Are the tests making any sense? Do you understand what is tested just by reading the names of the tests and the commands names?
-   In the custom commands, do you see something that could be reused in another panel? Something that could be a common command?
-   Spell check is always welcome
-   If new utils are added, you can review the logic in case we forgot something (errors handling, better algo, etc)

## What is the scope of the FTs

The scope of the FTs can be rather large but is limited to a normal use case (or happy path).
By its nature, an automated test can only validate predictible results or behavior and does not fair well in some corner cases or unanticipated errors (ex: the backend is dead and a warning modal appears in front of the page currently tested, hiding the element undeneath). An error warning can be tested only if we trigger the error **in** the tests, so then the behavior can be anticipated.

## How do I know what selectors QA will needs for the FTs

A good rule of thumbs is: Anything you think an end user will click/use:

-   Buttons
-   Text fields
-   Inputs
-   Facets
-   Tabs
-   Checkbox/radio buttons
-   Menus (ul-li, dropdowns, etc)
-   Modals

If you think that there's no good selector (ids or specific class) for QA, you can add a `[data-nightwatch-selector="nameOfYourChoosing"]` in the HTML tag of the element.
​
Another cue for good selectors missing could be when you are trying to find an element for a unit test and you have something like this: `.children().childAt().dive().at()`. If you have to go that deep to find your element, there's a good chance QA will have a similar bad time. In that case, instead of adding `data-nightwatch-selector` maybe an id would be a better idea, or a unique class if appropriate.
