---
title: 'Testing UI (work in progress)'
metaTitle: 'Admin-UI Docs | Tests > Testing UI'
metaDescription: 'Learn how to write tests for user interfaces'
---

> WORK IN PROGRESS
>
> This section must be rewritten and split into several articles, here are the tracks for the rewriting:
>
> -   create a doc on Unit Test
> -   create a doc on Integration Tests
> -   create a doc on End-to-End Tests
> -   create a doc on libraries used and their guidelines (ADR-003)

## React Testing Library

Our preferred method of testing user interfaces is React Testing Library, commonly referred as RTL. A good place to start is [RTL's documentation website](https://testing-library.com/docs/react-testing-library/intro).

### Our standards

-   Test the functionality, not the implementation.
-   Keep tests independent. No variables should be shared across test cases and no artifacts should remain in the DOM after a test is run.
-   Balance is key; the less things you mock, the more confidence you will have with your code, even if they will take more time to run.
-   Test a single functionality per test.
-   Name your test to clearly indicate what you want to validate.
-   Test every actions a user could do using your component (based on their privileges, environment, knowledge of the product...).
-   Quality over quantity. We would prefer to have no test than have a poorly written one that could lead to false positives or false negatives.
-   Less abstraction is better. Unlike in source code, repeating yourself can be a good thing if it makes the test clearer. Clarity is king

#### Useful links

-   [⭐️ The UI Testing Booster Pack ⭐️](https://source.coveo.com/2021/06/21/UI-testing-booster-pack/)
-   [Common mistakes](https://kentcdodds.com/blog/common-mistakes-with-react-testing-library)
-   [Tutorial on how to use React Testing Library](https://www.robinwieruch.de/react-testing-library)
-   [Example from the website](https://testing-library.com/docs/react-testing-library/example-intro)
-   [Queries cheatsheet](https://testing-library.com/docs/react-testing-library/cheatsheet)

#### Psst, here's a good way to debug while you develop your tests with React Testing Library

You could log the document or an element on your browser using _[testing-playground](https://testing-playground.com/)_. That way, you could find the element you want to interact with if you are not sure how your component has been rendered.

_[Example how to use it from the doc](https://testing-library.com/docs/queries/about/#screenlogtestingplaygroundurl)_

_[Link to add a plugin in Google Chrome](https://chrome.google.com/webstore/detail/testing-playground/hejbmebodbijjdhflfknehhcgaklhano?hl=en)_

> The more your tests ressemble the way your software is used, the more **_confidence_** they can give you. -- React Testing Library’s guiding principle.

## Running Tests in Admin-UI

If you want to run all the tests in a specific package or folder (e.g `components/empty-states`) you can run the following commands at root:

```bash
cd components/empty-states

pnpm test
```

Alternatively, you can simply run this command at root:

```bash
pnpm test --recursive --filter @components/empty-states
```

This will run all the tests inside the `empty-states` components folder.

### Running individual tests with hot reload

For example, if you want to run only the `CardSection.spec.tsx` inside `components/empty-state`, you can follow the steps below:

```bash
pnpm test:watch CardSection.spec.tsx
```

This will run the single test file and watch for any changes so that it will re-run the test automatically.

## Debugging RTL tests

With React Testing Library, there's a simple log you can add to your test to see visually what's being rendered:

`screen.logTestingPlaygroundURL()`

This will output a URL you can click on to see what the test renders and allow you to use the selector tool to help you test.

## Goal

What you want to achieve when making unit test is to write maintainable tests for your React components. It must be structured in a way that resemble how a user would use our software and avoid testing implementation details.

## When should you write unit tests?

-   When the implementation of a new feature is made (component, utility functions, etc.)
-   When there is a refactor
-   When it's for a bug fix

## Basic concepts

Before thinking of what you should test, let's start with some essentials that you need to do in order to get started.

## Folder structure for tests

When creating a test in the Admin-UI repository, there are 3 guidelines to follow:

1. The `__tests__` folder should be at the same level of your `.ts` and `.tsx` files
2. The test file is the same name as the component being tested
3. The additional of `.spec` is required between the file name and the extension (e.g `YourComponent.tsx` test file should be named `YourComponent.spec.tsx`).

This is the type of structure you should have after creating your tests folders and files:

```
│
└─ foo-component
    │   FooTable.tsx
    │   FooHeader.tsx
    │   FooButton.tsx
    │   FooConstants.ts
    │   ...
    │
    └───__tests__
    │   │   FooTable.spec.tsx
    │   │   FooHeader.spec.tsx
    │   │   FooButton.spec.tsx
    │   │   ...
    │
    └───FooUtils
    │   │   FooTableUtils.ts
    │   │
    │   └───__tests__
    │   │   FooTableUtils.spec.ts
    ...
```

## Example of testing a simple React component

Let's take a simple Button example to cover what a good test suite should look like.

![](./images/testing-ui-some-button-component.png)

From this component, there are basically 3 things we can cover with unit tests:

1. The name of the button is correctly being shown
2. Checking whether the button will be enabled or disabled based on the conditions
3. The onClick method being called when clicked

Inside the test file, you should wrap everything inside `describe` and `it`.

The `describe` statement creates a block that groups several related tests defined with `it` statements.

What you will need in your tests files is to import methods (with some modifications for our project) from the `octopus.tsx` file of the project you are working in that will help you build your tests suite

Here is an example of a simple unit test that verifies that the button is rendered (with the name).

![](./images/testing-ui-test-button-name.png)

The `beforeEach` and `afterEach` are used when you want something specific to run before or after each tests in the test suite (api calls with specific returned values, spy functions, etc), so you don't have to repeat it in every test.

The `it` statement defines the unit test. You want to write exactly what the test does. In this case we want to see that by rendering `<SomeComponent />`, it's going to render a button with `"super cool button"` as name.

`render` is an RTL method that renders a React Element into a container which is appended to `document.body` from the DOM.

`screen` is an object that has every query that is pre-bound to `document.body`. RTL exports it so you can use it to make those tests.

Here is the [cheatsheet](https://testing-library.com/docs/react-testing-library/cheatsheet) for all of the queries you can use depending on your test, but RTL also provides a really useful method:

```
screen.logTestingPlaygroundURL();
```

This method will most likely become your best friend (or worst when you forget to handle api calls or loading logic).
[It renders the whole component in an URL](https://testing-playground.com/#markup=DwEwlgbgBGILwCIC2BDMA7BA+YBnADiulAMYA2KuuiARgC7oC0JA9unWugKYBOCUKHmBSMKNLmTJcQNAJ6I6LFmTph8jABzZgNAK51FxcpWoJ6xJCxCN8Q1D1lRcHOl0bhcKGlJBQPXn35-b2lEbABlXXxeKABhJTIoACF9Q2AAej0DNhx0giJc8AgcIph4ZCsUMlxtdKKSyDLEEB4WfBAWAHd0GsLILCA) (that is printed in your console/terminal) and suggests queries for you to work through your test. Let's take the test above and add `screen.logTestingPlaygroundURL()`.

![](./images/testing-ui-test-suite-layout.png)

The screenshot below show what the component renders and the selector for the button (after selecting it).

![](./images/testing-ui-testing-playground.png)

As you can see it does render a button and when you click on it, you can see that RTL is suggesting a query that will return the specific element. In this case, the button.

What you may have noticed is that the button is disabled. That's because we have not spied on the method that enables or disables the button (by default it returns undefined, which makes it disabled).

Here is the test to enable the button.

![](./images/testing-ui-test-button-enable.png)

We are using `spyOn` method to stub the function that returns `true` so it will enable the button.

Lastly, we test the `onClick` method of the button.

![](./images/testing-ui-test-button-onclick.png)

We have define that prop in a way that it calls `createSource()` which makes a call to the API. By spying on `Platform.source` on the specific `create` method, we are able to verify that the right endpoint was call with the parameters expected from the call.

As you can see in the last screenshot, we use `userEvent` which simulates user interactions by dispatching the events that would take place in a browser, hence doing exactly what we initially want : **Testing the same way our browser is used**.

For more information about user events, queries or async methods in unit test, refer to the [React Testing Library](https://testing-library.com/docs/) website.

Other examples:

-   [Modal Wizard](https://github.com/coveo/plasma/blob/master/packages/react/src/components/modalWizard/tests/ModalWizard.spec.tsx)
-   [Text Input](https://github.com/coveo/plasma/blob/master/packages/react/src/components/textInput/tests/TextInput.spec.tsx)
-   [Multi Select with Predicates](https://github.com/coveo/plasma/blob/master/packages/react/src/components/select/hoc/tests/MultiSelectWithPredicate.spec.tsx)
