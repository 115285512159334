---
title: 'Guides'
metaTitle: 'Admin-UI Docs | Guides'
metaDescription: 'A summary page of the guides that will help you develop in Admin-UI'
---

Here, you will learn about the following:

-   Creating a package
-   Creating a page
-   How to localize strings
-   Performance optimizations
-   Adding a resource in Platform client
