---
title: '@core/configuration'
metaTitle: 'Admin-UI | Core - Configuration package'
metaDescription: 'Information about our core configuration package'
---

This package contains the configuration (URLs, Resource IDs, etc...) of the different cloud environments.

It parses the URL and detect in which environment the application is running, exposing an object for the current environment.

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/configuration@*"

or

pnpm add "@core/configuration@*" --recursive --filter <your-package>
```

## User Guide

It offers no method, only static objects and constants. To learn more, you can see [the file `Configuration.ts`](https://github.com/coveo/admin-ui/blob/master/core/configuration/src/Configuration.ts).
