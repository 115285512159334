---
title: '@core/registry'
metaTitle: 'Admin UI | Core - Registry package'
metaDescription: 'A deeper look into this core package'
---

The registry provides a way to store and retrieve information on a global application scale.

**What type of information should go in the registry?**

Information that is:

-   not meant to change
-   only known at runtime
-   shared across packages

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/registry@*"

or

pnpm add "@core/registry@*" --recursive --filter <your-package>
```

## User Guide

### How can I register a data at shared?

```typescript
import Registry from '@core/registry';

Registry.register('access_token', 'my-token');
```

### How can I get shared data?

```typescript
import Registry from '@core/registry';

Registry.get('access_token');
```

### How can I clear all data?

```typescript
import Registry from '@core/registry';

Registry.clear();
```
