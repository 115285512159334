import {
    Anchor,
    Badge,
    Button,
    ColumnDef,
    createColumnHelper,
    EmptyState,
    getFacetedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    MantineColor,
    Paper,
    Table,
    TableProps,
    useTable,
} from '@components/mantine';
import {fileOwnership, Framework, type OwnedFile} from '@core/debug';
import {FunctionComponent, useMemo} from 'react';
import classes from './FileOwnershipTable.module.css';

const columnHelper = createColumnHelper<OwnedFile>();

const FrameworkColorMapping: Record<Framework, MantineColor> = {
    Backbone: 'critical',
    PlasmaReact: 'warning',
    Mantine: 'success',
};

const columns: Array<ColumnDef<OwnedFile>> = [
    columnHelper.accessor('owner', {
        header: 'Owner',
    }),
    columnHelper.accessor('framework', {
        header: 'Framework',
        enableSorting: false,
        cell: ({getValue}) => {
            const value = getValue();
            const frameworks = Array.isArray(value) ? value : [value];
            return frameworks.map((framework) => (
                <Badge key={framework} color={FrameworkColorMapping[framework]}>
                    {framework}
                </Badge>
            ));
        },
    }),
    columnHelper.accessor('filePath', {
        header: 'File',
        cell: ({getValue, row}) => (
            <Anchor
                href={`https://github.com/coveo-platform/admin-ui/tree/master/${getValue()}#L${row.original.lineNumber}`}
            >
                {getValue()}
            </Anchor>
        ),
    }),
    columnHelper.display({
        cell: ({row}) => `${row.original.parent} > ${row.original.component}`,
        header: 'Component',
    }),
];

const options: TableProps<OwnedFile>['options'] = {
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'auto',
};

export const FileOwnershipTable: FunctionComponent = () => {
    const table = useTable<OwnedFile>({
        initialState: {
            predicates: {framework: '', owner: ''},
            pagination: {pageSize: 10, pageIndex: 0},
            sorting: [{id: 'owner', desc: false}],
        },
    });
    const owners = useMemo(() => Array.from(new Set(fileOwnership.map(({owner}) => owner))).sort(), [fileOwnership]);
    const frameworks = useMemo(
        () => Array.from(new Set(fileOwnership.flatMap(({framework}) => framework))).sort(),
        [fileOwnership],
    );

    // manually apply predicates
    const data = useMemo(
        () =>
            fileOwnership
                .filter(
                    ({framework}) =>
                        table.state.predicates.framework === '' ||
                        table.state.predicates.framework === framework ||
                        (Array.isArray(framework) && framework.includes(table.state.predicates.framework as Framework)),
                )
                .filter(({owner}) => table.state.predicates.owner === '' || table.state.predicates.owner === owner),
        [table.state.predicates],
    );

    return (
        <Paper radius="md" withBorder className={classes.container}>
            <Table<OwnedFile>
                store={table}
                data={data}
                columns={columns}
                options={options}
                getRowId={({filePath, lineNumber}) => `${filePath}:${lineNumber}`}
            >
                <Table.Header>
                    <Table.Filter placeholder="Search" flex="1" display="flex" miw={100} />
                    <OwnerPredicate owners={owners} />
                    <FrameworkPredicate frameworks={frameworks} />
                </Table.Header>
                <Table.Footer>
                    <Table.PerPage values={[10, 25, 100]} />
                    <Table.Pagination />
                </Table.Footer>
                <Table.NoData>
                    <EmptyState>
                        <EmptyState.Title order={4}>Nothing found for "{table.state.globalFilter}"</EmptyState.Title>
                        <Button onClick={table.clearFilters}>Clear filter</Button>
                    </EmptyState>
                </Table.NoData>
            </Table>
        </Paper>
    );
};

const FrameworkPredicate: FunctionComponent<{frameworks: Framework[]}> = ({frameworks}) => (
    <Table.Predicate
        id="framework"
        data={[{value: '', label: 'All'}, ...frameworks.map((framework) => ({value: framework, label: framework}))]}
        label="Framework"
    />
);

const OwnerPredicate: FunctionComponent<{owners: Array<OwnedFile['owner']>}> = ({owners}) => (
    <Table.Predicate
        id="owner"
        data={[{value: '', label: 'All'}, ...owners.map((owner) => ({value: owner, label: owner}))]}
        label="Owner"
    />
);
