---
title: 'Configuring your setup'
metaTitle: 'Admin-UI Docs | Getting started > Configuring your machine'
metaDescription: 'Explains what is needed for your machine to run Admin-UI project locally'
---

This documentation will guide you on making sure you have the right setup for your machine to run Admin-UI locally and be able to contribute to the repo.

## 1 - Configuring your GitHub settings

### Access to the right workgroup

Make sure you have been added to the [Coveo Platform organization](https://github.com/coveo-platform) on GitHub, otherwise you must [send a request via our CoveoGO portal](https://www.coveoat.coveo.com/coveogo-forms) to the IT department.

If you are a new core admin-ui developer, you will need to be added as a member of the [developers team](https://github.com/orgs/coveo-platform/teams/developers).

### Adding your SSH key

The GitHub documentation is really well done and will help you [set up your SSH key configuration](https://docs.github.com/en/authentication/connecting-to-github-with-ssh/adding-a-new-ssh-key-to-your-github-account) or even [generate one if you don't have one yet](https://docs.github.com/en/authentication/connecting-to-github-with-ssh/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent).

### Adding your GPG to sign your commits (required by the security team)

The GitHub documentation is really well done and will help you [set up your GPG key configuration](https://docs.github.com/en/authentication/managing-commit-signature-verification/adding-a-new-gpg-key-to-your-github-account) or even [generate one if you don't have one yet](https://docs.github.com/en/authentication/managing-commit-signature-verification/generating-a-new-gpg-key).

## 2 - Install Git

To install [Git](https://git-scm.com/), please use the commands below for your corresponding operating system:

### Git on Linux

This command allows us to add the Git PPA repository to install the latest version of Git.
If we install the official ubuntu version, we'll have an out-of-date and corrupted version of Git.

```bash
sudo add-apt-repository ppa:git-core/ppa
sudo apt update && sudo apt upgrade -y git
```

### Git on OSX

```bash
brew install git
```

### Git on Windows

1. Go to https://git-scm.com/download/win
2. Download the installer and execute it by following the wizard

## 3 - Configure Git

```bash
git config --global user.name "your fullname"
git config --global user.email my@email.com
```

The use of a `.gitignore` file at the global level is part of good development practice.
You will avoid adding rules to ignore that only match your development environment in the `.gitignore` files of projects versioned with Git.

Here is an example of a `.gitignore_global` file for the overall configuration of your development platform that you must change according to your needs.

```bash
###################
# Compiled source #
###################
*.com
*.class
*.dll
*.exe
*.o
*.so

############
# Packages #
############
# it's better to unpack these files and commit the raw source
# git has its own built in compression methods
*.7z
*.dmg
*.gz
*.iso
*.jar
*.rar
*.tar
*.zip

######################
# Logs and databases #
######################
*.log
*.sql
*.sqlite
get_dump.sh

######################
# OS generated files #
######################
.DS_Store
.DS_Store?
._*
.Spotlight-V100
.Trashes
ehthumbs.db
Thumbs.db
*~
.*.swp

#######
# IDE #
#######
nbproject
.idea
.project
.buildpath
.project
.code-workspace
.vscode
.setting/org.eclipse.php.core.prefs
.settings/org.eclipse.wst.common.project.facet.core.xml

##########
# DOCKER #
##########
docker-compose.override.yml
docker-compose.override.yaml

#########
# Other #
#########
issue.md
todo.md
```

### Configure Git on Linux & OSX

```bash
# configure your gitignore file for your global configuration (this file is used for to ignore all files of your development platform)
git config --global core.excludesfile "$HOME/.gitignore_global"
```

### Configure Git on Windows

```bash
# configure your gitignore file for your global configuration with PowerShell (this file is used for to ignore all files of your development platform)
git config --global core.excludesfile "$HOME\.gitignore_global"
```

## 4 - Install NVM

[Node Version Manager](https://github.com/nvm-sh/nvm) (or NVM) makes it easier to install and update Node.js, we strongly advise to manage Node.js version this way instead of installing it manually.

Please uninstall any existing versions of NVM, Node.js and NPM before installing NVM.

### NVM on Linux

The command which follows is currently used for the installation but [we advise you to visit the creator's repository for more complete documentation](https://github.com/nvm-sh/nvm#installing-and-updating)

```bash
# go to https://github.com/nvm-sh/nvm/releases to get the latest version number (in my case it was 0.40.1)
curl -o- https://raw.githubusercontent.com/nvm-sh/nvm/v0.40.1/install.sh | bash
```

### NVM on OSX

For the installation of NVM on OSX we offer 2 choices:

1. [installation as on Linux with the installer from the creator's repository](https://nodesource.com/blog/installing-node-js-tutorial-using-nvm-on-mac-os-x-and-ubuntu/)
2. [installation via homebrew which should be easier](https://tecadmin.net/install-nvm-macos-with-homebrew/)

### NVM on Windows

For the installation of NVM on Windows you can follow [the installation described on the nvm-windows project repository](https://github.com/coreybutler/nvm-windows#installation--upgrades).

### Usage

You can set or install the correct version of node as follows:

```bash
nvm use
```

## 5 - Install Node.js with NVM

To install [Node.js](https://nodejs.org/en/), please open a terminal (use PowerShell or cmd with Windows)

```bash
# install the current LTS version of Node.js
nvm install --lts --default
nvm install-latest-npm

# verify the installation
node --version
npm --version
```

## 6 - Install PNPM

To install [PNPM](https://pnpm.io/installation), please open a terminal (use PowerShell or cmd with Windows)

```bash
# install the latest version of pnpm with npm
npm install --global pnpm

# or install the latest version of pnpm with npx
npx pnpm add --global pnpm

# verify the installation
pnpm --version

# make sure it's set up to use the package-lock file
pnpm config set package-lock true
```

To change your pnpm version, run `pnpm update --global pnpm` in your terminal.

## 7 - Update the host file (optional)

The `localhost.corp.coveo.com` URL is required to share your local demos with your peers on the coveo local network.
The `local.cloud.coveo.com` URL is required to use our applications in HTTPS locally.

### on Linux & OSX

Edit `/etc/hosts` with admin rights and add this line.

```bash
127.0.0.1 localhost.corp.coveo.com
127.0.0.1 local.cloud.coveo.com
```

### on Windows

Edit `C:\Windows\System32\drivers\etc\hosts` with admin rights and add this line.

```bash
127.0.0.1 localhost.corp.coveo.com
127.0.0.1 local.cloud.coveo.com
```
