---
title: 'Core packages'
metaTitle: 'Core packages'
metaDescription: 'This section contains the summary of core packages'
---

Core packages are packages in admin-ui that serves as a tool to help create specific files and templates to start working on.

Here, you will learn about the following

-   API structure
-   Organization functionality
