---
title: 'Performance optimizations'
metaTitle: 'Admin-UI Docs | Best practices > Performance optimizations'
metaDescription: 'Explains code splitting to make hotreoading faster'
---

There are multiple ways to make sure our application follow best practices, this file will list some of them. Feel free to contribute!

## [Hot-Reload] Keep dependencies up-to-date

Often times the new releases of dependencies come with performance improvements so staying up-to-date is an easy way to benefit from those.

## [Hot-Reload] Add split points in the code

At the time of writing this, the delay between saving a file inside v2 and the response from the browser to update (hot-reload) is around 10 seconds. We can do a lot better by adding split points.

[Dynamic imports are key to make the hot reload faster](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#dynamic_imports). The `Loadable` component is a simple wrapper around [`@loadable/component`](https://www.npmjs.com/package/@loadable/component) using our `Loading` component as a fallback. Here's an example of a split point we put in our code:

Before:

```typescript jsx
import {TrialHomePage} from './TrialHomePage';
// ...
this.renderMain(
    <Provider store={AdminStore}>
        <TrialHomePage />
    </Provider>,
    true
);
```

After:

```typescript jsx
import {Loadable} from '@coveord/jsadmin-common';
// ...
const TrialHomePage = Loadable(() => import('./TrialHomePage').then((exports) => exports.TrialHomePage));
this.renderMain(
    <Provider store={AdminStore}>
        <TrialHomePage />
    </Provider>,
    true
);
```

With the change above, a modification inside `TrialHomePage` or one of the component, the hot-reload takes approximately **3s**.

Keep in mind, for the split points to work, all files importing `TrialHomePage` should use a dynamic import. If you fail to do so, Webpack will put the code in the main bundle and the hot-reload time won't decrease. Also, the component should not be exported by the entry file of the package because it cannot use a dynamic import.

## [Hot-Reload] Splitting with SCSS inline

In rare cases where you need to add custom CSS to the Admin-UI repo for your component, you can simply import them instead of having to do an `@import` in the `styles.scss` of the V2 package. This also helps with code splitting and performance in the Admin-UI.

For example, inside of the `<TrialHomePage />` component, you can import the scss file like so:

```jsx
import './styles/homepage.scss';
```

Doing this could help with faster hot-reloading with your CSS changes.

## [Hot-Reload] Make sure dependencies are only included once

We have a lot of dependencies in the project and our bundle is quite big, but having duplicate of dependencies makes it bigger for no good reason. It gives more work to our bundler that needs to parse everything, and it takes precious time.
