---
title: 'Mantine 7 Upgrade ETA'
metaTitle: 'Admin-UI Docs | Announcements > Mantine 7 Upgrade ETA'
metaDescription: ''
---

In our last announcement about our commitment to quality, we mentioned we "are currently working on migrating from Mantine 6 to Mantine 7". As we know some of you are actively waiting on this, some others might be wondering why are we doing this now, some might think about how upgrading to the newest version of Mantine will impact their work. In this post, we'll answer those questions in order to provide maximal level of transparency regarding this transition.

# Why is it worth doing?

Why is it worth updating any dependency? Why is it worth maintaining the app? Why is it worth paying out technical debt? It is worth it for many reasons that we all know intuitively, including security patching, performance enhancements, compatibility, feature updates, community support, dependency chains, future proofing. The answers to these questions all apply here, but we're going to concentrate specifically on the technical specificities of the Mantine 6-7 transition.

1. Mantine 7 unlocks features that will significantly speed up the admin-ui's compliance with the "Opal" project, that aims to standardize styles across all Coveo products.
2. Mantine 7 provides better theming customizations, which will reduces the need for ad-hoc custom styles. Custom styles directly lead to visual inconsistencies for our clients. They also create a lot of duplicated code, adding up to the general maintenance cost of features.
3. Mantine 7 improves performance and reduces bundle size compared to Mantine 6.
4. We are already one year late to do the update, the more we delay the harder it is to update. Teams are increasingly using Mantine, creating more code to update with each day that passes. We simply cannot stay on V6 forever without hitting a wall at some point.
5. When devs go look at the official Mantine documentation, discord community and various support and examples, they are viewing the V7 documentation, not the V6. This simple fact decreases devs velocity, and increases questions we receive in the guild.

# How does the transition impact current features?

Mantine 7 touches mainly on the visuals (CSS), but also some components were replaced by a new implementation. The main one to look for is the Select component and its variants like the MultiSelect. Complex use-cases of the Select component were replaced by a more flexible yet longer to implement Combobox.
The changes are well explained on the official documentation

-   [https://mantine.dev/guides/6x-to-7x/](https://mantine.dev/guides/6x-to-7x/)
-   [https://mantine.dev/changelog/7-0-0/](https://mantine.dev/changelog/7-0-0/)

# When will the upgrade be done?

The frontend-foundation team is actively working on this at the time of writing.

## In the admin-ui

The upgrade comes with a lot of breaking changes, but we made significant progress. We estimate to have fully upgraded the admin-ui project including the Commerce Merchandizing Hub by mid-March.

## In plasma

The plasma-mantine repository already publishes preliminary versions that support Mantine version 7. Those version can be installed by dependent projects using the `@next` tag (`npm install @coveord/plasma-mantine@next`). Once we are satisfied with the stability of those changes, we will merge them in the `master` branch and publish them under the `@latest` tag.

## In other projects

The frontend-foundation team won't transition any other projects to Mantine 7 ourselves. However, if you need assistance you can reach out to us in the #admin-ui-guild channel and we'll be happy to assist you.
