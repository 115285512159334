---
title: 'Using Plasma components (Deprecated)'
metaTitle: 'Admin-UI Docs | Learn the basics > Using Plasma components (Deprecated)'
metaDescription: 'How to create visual interfaces using Plama components'
---

> DEPRECATED
>
> For new UI features, please use [Mantine](https://mantine.dev/pages/getting-started/).
>
> You can follow our Mantine implementation in the [`@components/mantine` package](https://github.com/coveo/admin-ui/tree/master/components/mantine).
> Soon, we will replace part of Plasma with Mantine components.
>
> In the meantime, we leave this documentation accessible for existing code.

## What is Plasma?

Plasma is the Coveo’s platform design system. It is [open source on GitHub](https://github.com/coveo/plasma) and has [a website](https://plasma.coveo.com/) where you can check the different components and play with them.

![Plasma Logo](./images/plasma-logo.png)

### Packages

-   `@coveord/plasma-tokens`: Design tokens extracted from Figma.
-   `@coveord/plasma-react-icons`: React components for every icon extracted from the token library
-   `@coveord/plasma-style`: Style of design system components .
-   `@coveord/plasma-react`: React implementation of the design system components. This is the one you'll use the most.

### History

Plasma, or Vapor, or Coveo Styleguide started as a hackathon project in 2014. It was inspired by other projects like Bootstrap and Materialize but started to gather dust on the side.
In September 2015, there was a power outage at the office over multiple days. Developer couldn’t access mercurial and had to find something else to work on.

That's when we thought about the hackathon and the project was dusted off. The very next week the first version of the coveo-styleguide (codename Vapor) was published on NPM.
It was only CSS at first, then it evolved as we added React components (react-vapor). The project was targeted at the admin-ui without much consideration for other teams.

Skip a few years and another hackathon happened and Plasma was born. Plasma is the natural evolution of Vapor, with more consideration towards the design team and other delveopers.
A styleguide became a design system.

## Website

On the Plasma website you'll find all the components that are part of the design system. The search is powered by Coveo, make sure to use it!

![Plasma Website](./images/plasma-website.png)

For every one of them you'll have a demo page showing:

1. Code Sandbox
2. Props (if any)
3. Examples
4. Guidelines

![Plasma Component Page](./images/plasma-component.png)

### Code Sandbox

This is probably one of the best place to try a component. You have instant feedback when you modify the code, autocomplete, errors, etc.

### Props

When a component has props, you'll see them displayed in a table with additional information:

| Name                              | Type               | Default  | Description                                  |
| --------------------------------- | ------------------ | -------- | -------------------------------------------- |
| \{Name} \{required} \{deprecated} | \{TypeScript type} | \{value} | \{Description}                               |
| E.g., `label` required            | `string`           |          | The label of the badge (Required if no icon) |

### Examples

For most components you'll have more code examples showing different use case for the component you're looking at. They also use an editable code sandbox

### Guidelines

For some components you'll also have the UX guidelines on a separate tab. Those guidelines contain the do and don't of the component usage, related components, etc.

## How can I use Plasma?

To use a Plasma component in the admin is really simple. All the plasma dependencies are already setup so copying and pasting the sandbox code is a great starting point.

```typescript jsx
import * as React from 'react';
import {Button} from '@coveord/plasma-react';

export default () => <Button>Hello World!</Button>;
```

UX mockups are supposed to use Plasma components for every element which makes it easy to implement. If you're not sure which component is used in the mockup, feel free to [ask the guild](https://coveo.slack.com/archives/C01M10DME91)!

As a starting developer in the admin-ui you should compose interface with the existing components and implement the business logic.
You shouldn't have to spend much time doing CSS or creating new components.

To work with Plasma components you should have a good understanding of [State management](/learn-the-basics/state-management) and [Forms](/learn-the-basics/forms).
Most Plasma form component use Redux. We export some selectors which makes it easy to get the data from a specific component.
The rule of thumb is that to get the state of a component, you need to use ComponentSelectors. E.g.,

-   `Checkbox` => `CheckboxSelectors`
-   `CodeEditor` => `CodeEditorSelectors`
-   `Filepicker` => `FilepickerSelectors`
-   ...
